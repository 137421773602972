import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  List,
  ListItem,
  ListItemPrefix,
  Alert,
} from "@material-tailwind/react";
import { ArrowsPointingOutIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { motion, AnimatePresence } from "framer-motion";
import { GET } from '../utils/ApiHandler';

const Fertigation = () => {
  const { token, tenantId } = useSelector((state) => state.login);
  const [isLoading, setIsLoading] = useState(true);
  const [processes, setFertigation] = useState([]);
  const [projectStartDate, setProjectStartDate] = useState(null);
  const [selectedProcess, setSelectedProcess] = useState(null);

  const calculateCurrentWeek = (startDate) => {
    const start = new Date(startDate);
    const now = new Date();
    const diffInTime = now.getTime() - start.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);
    return Math.ceil(diffInDays / 7);
  };

  useEffect(() => {
    fetchProjectStartDate();
  }, []);

  useEffect(() => {
    if (projectStartDate) {
      getProcesses();
    }
  }, [token, projectStartDate]);

  const fetchProjectStartDate = async () => {
    try {
      setIsLoading(true);
      const response = await GET(`${process.env.REACT_APP_API_URL}/api/v1/tenants/list?tenantId=${tenantId}`, token);
      const startDate = response.data.tenants[0]?.project_start_date;
      setProjectStartDate(startDate || null);
    } catch (error) {
      console.error("Error fetching project start date:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getProcesses = async () => {
    try {
      const processData = await GET(`${process.env.REACT_APP_API_URL}/api/v1/fertigation/list?tenantId=${tenantId}`, token);
      if (processData && processData.data) {
        const currentWeek = calculateCurrentWeek(projectStartDate);
        const currentWeekProcesses = processData.data.process.filter(process => process.week_number == currentWeek);
        setFertigation(currentWeekProcesses);
      }
    } catch (e) {
      console.error("Error fetching Fertigation process data:", e);
    } finally {
      setIsLoading(false);
    }
  };

  const renderMedia = (media) => {
    if (!media || media.length === 0) return null;

    return (
      <div className="flex gap-2 mt-2">
        {media.map((item, index) => {
          const isVideo = item.sasUrl.toLowerCase().includes('.mp4') || item.sasUrl.toLowerCase().includes('.mpeg');
          return (
            <motion.div
              key={index}
              className="w-16 h-16 overflow-hidden rounded-lg"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {isVideo ? (
                <video src={item.sasUrl} className="w-full h-full object-cover" />
              ) : (
                <img src={item.sasUrl} alt={item.fileName} className="w-full h-full object-cover" />
              )}
            </motion.div>
          );
        })}
      </div>
    );
  };

  return (
    <Card className="w-full h-full overflow-hidden bg-gradient-to-br from-green-400 to-blue-500">
      <CardHeader floated={false} shadow={false} className="rounded-none bg-transparent">
        <div className="flex items-center justify-between">
          <Typography variant="h5" color="white" className="font-bold">
            FERTIGATION
          </Typography>
          <Link to="processhistory">
            <Button
              size="sm"
              variant="text"
              className="flex items-center gap-2 text-white"
            >
              <span>View All</span>
              <ArrowsPointingOutIcon strokeWidth={2} className="w-4 h-4" />
            </Button>
          </Link>
        </div>
      </CardHeader>
      <CardBody className="p-0">
        {isLoading ? (
          <div className="flex items-center justify-center h-64">
            <Typography variant="h6" color="white">
              Fetching Data...
            </Typography>
          </div>
        ) : processes.length > 0 ? (
          <div className="p-4">
            <Alert color="blue" className="mb-4 relative">
              <Typography variant="h6" color="black">
                {processes[0].title}
              </Typography>
              <span className="absolute right-0 top-0 text-black font-bold mt-2 mr-4">
                Week {processes[0].week_number}
              </span>
            </Alert>
            <List>
              <AnimatePresence>
                {processes.map((process, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                  >
                    <ListItem
                      className="p-0 focus:bg-blue-gray-50/80 hover:bg-blue-gray-50/80 active:bg-blue-gray-50/80 rounded-lg mb-2"
                      selected={selectedProcess === index}
                      onClick={() => setSelectedProcess(selectedProcess === index ? null : index)}
                    >
                      <label htmlFor={`list-${index}`} className="flex w-full cursor-pointer items-center px-3 py-2">
                        <ListItemPrefix>
                          <ChevronRightIcon
                            strokeWidth={3}
                            className={`h-3 w-5 ${selectedProcess === index ? "rotate-90" : ""} transition-transform`}
                          />
                        </ListItemPrefix>
                        <Typography color="blue-gray" className="font-medium">
                          {process.subtitle}
                        </Typography>
                      </label>
                    </ListItem>
                    <AnimatePresence>
                      {selectedProcess === index && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.3 }}
                          className="px-8 py-2 bg-white/30 rounded-lg mb-2"
                        >
                          <Typography className="text-sm text-blue-gray-500">
                            {process.description}
                          </Typography>
                          {renderMedia(process.media)}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.div>
                ))}
              </AnimatePresence>
            </List>
          </div>
        ) : (
          <div className="flex items-center justify-center h-64">
            <Typography variant="h6" color="white">
              No Data Available
            </Typography>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default Fertigation;