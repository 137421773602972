import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  Input,
  Select,
  Option,
  IconButton,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
} from "@material-tailwind/react";
import {
  PlusIcon,
  PencilIcon,
  TrashIcon,
  EyeIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";
import bg from "../../Assets/Services/s2.jpg";

const headings = ['Land Acquisition', 'Climatic Conditions', 'Fencing and Basic Infrastructure', 'Legal Opinion', 'Sample Test'];
const subheadings = ['Geographical Conditions', 'Physical Conditions of the Site', 'Soil Condition', 'Water Source', 'Weather Conditions', 'Border Fencing', 'Legal Opinion', 'Sample Test'];

const FormBuilder = () => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState({ text: '', type: 'checkbox', options: [], heading: '', subheading: '' });
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/questions`);
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleAddQuestion = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/questions`, newQuestion);
      setNewQuestion({ text: '', type: 'checkbox', options: [], heading: '', subheading: '' });
      fetchQuestions();
      setIsFormOpen(false);
    } catch (error) {
      console.error("Error adding question:", error);
    }
  };

  const handleUpdateQuestion = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/questions/${editingQuestion._id}`, editingQuestion);
      setEditingQuestion(null);
      fetchQuestions();
      setIsFormOpen(false);
    } catch (error) {
      console.error("Error updating question:", error);
    }
  };

  const handleDeleteQuestion = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/questions/${id}`);
      fetchQuestions();
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };

  const handleViewAnswers = () => {
    navigate("/admin/onboardtenant/formdata");
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="w-full shadow-xl">
        <CardHeader floated={false} className="h-80">
          <div className="relative h-full w-full overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white">
            <img
              src={bg}
              alt="Form Builder"
              className="h-full w-full object-cover"
            />
            <div className="absolute inset-0 h-full w-full bg-gradient-to-tr from-black/75 to-transparent">
              <div className="flex h-full items-center justify-between p-6">
                <Typography variant="h3" color="white">
                  Tenant Onboarding Data Acquisition Form Builder Tool
                </Typography>
                <Button
                  size="sm"
                  color="white"
                  variant="text"
                  className="flex items-center gap-2"
                  onClick={handleViewAnswers}
                >
                  <EyeIcon className="h-4 w-4" /> View Submitted Data
                </Button>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody className="px-6 py-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Button
              className="mb-6"
              color="blue"
              onClick={() => setIsFormOpen(!isFormOpen)}
            >
              {isFormOpen ? 'Close Form' : 'Add New Question'}
            </Button>
          </motion.div>

          {isFormOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="mb-6"
            >
              <Card className="w-full shadow-md">
                <CardBody>
                  <div className="mb-4">
                    <Input
                      label="Question Text"
                      value={editingQuestion ? editingQuestion.text : newQuestion.text}
                      onChange={(e) => editingQuestion ? setEditingQuestion({ ...editingQuestion, text: e.target.value }) : setNewQuestion({ ...newQuestion, text: e.target.value })}
                    />
                  </div>
                  <div className="mb-4">
                    <Select
                      label="Heading"
                      value={editingQuestion ? editingQuestion.heading : newQuestion.heading}
                      onChange={(value) => editingQuestion ? setEditingQuestion({ ...editingQuestion, heading: value }) : setNewQuestion({ ...newQuestion, heading: value })}
                    >
                      {headings.map((heading, index) => (
                        <Option key={index} value={heading}>
                          {heading}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="mb-4">
                    <Select
                      label="Subheading"
                      value={editingQuestion ? editingQuestion.subheading : newQuestion.subheading}
                      onChange={(value) => editingQuestion ? setEditingQuestion({ ...editingQuestion, subheading: value }) : setNewQuestion({ ...newQuestion, subheading: value })}
                    >
                      {subheadings.map((subheading, index) => (
                        <Option key={index} value={subheading}>
                          {subheading}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="mb-4">
                    <Select
                      label="Type"
                      value={editingQuestion ? editingQuestion.type : newQuestion.type}
                      onChange={(value) => editingQuestion ? setEditingQuestion({ ...editingQuestion, type: value }) : setNewQuestion({ ...newQuestion, type: value })}
                    >
                      <Option value="checkbox">Checkbox</Option>
                      <Option value="dropdown">Dropdown</Option>
                      <Option value="textarea">Text Area</Option>
                      <Option value="files">File (Image/Video/Document)</Option>
                    </Select>
                  </div>
                  {(editingQuestion ? editingQuestion.type : newQuestion.type) === 'dropdown' && (
                    <div className="mb-4">
                      <Input
                        label="Options (comma-separated)"
                        value={editingQuestion ? editingQuestion.options.join(',') : newQuestion.options.join(',')}
                        onChange={(e) => {
                          const options = e.target.value.split(',');
                          editingQuestion ? setEditingQuestion({ ...editingQuestion, options }) : setNewQuestion({ ...newQuestion, options });
                        }}
                      />
                    </div>
                  )}
                  <Button
                    className="mt-4"
                    color="blue"
                    onClick={editingQuestion ? handleUpdateQuestion : handleAddQuestion}
                  >
                    {editingQuestion ? 'Update Question' : 'Add Question'}
                  </Button>
                </CardBody>
              </Card>
            </motion.div>
          )}

          <Typography variant="h5" color="blue-gray" className="mb-4">
            Questions List
          </Typography>
          <List>
            {questions.map((question, index) => (
              <motion.div
                key={question._id}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <ListItem className="py-4 pr-1 pl-4">
                  <ListItemPrefix>
                    <Chip
                      value={question.type}
                      color={question.type === 'checkbox' ? 'blue' : question.type === 'dropdown' ? 'green' : question.type === 'textarea' ? 'amber' : 'red'}
                      className="mr-2"
                    />
                  </ListItemPrefix>
                  <div>
                    <Typography variant="h6" color="blue-gray">
                      {question.text}
                    </Typography>
                    <Typography variant="small" color="gray" className="font-normal">
                      Heading: {question.heading} | Subheading: {question.subheading}
                    </Typography>
                  </div>
                  <ListItemSuffix>
                    <IconButton
                      variant="text"
                      color="blue-gray"
                      onClick={() => setEditingQuestion(question)}
                    >
                      <PencilIcon className="h-5 w-5" />
                    </IconButton>
                    <IconButton
                      variant="text"
                      color="red"
                      onClick={() => handleDeleteQuestion(question._id)}
                    >
                      <TrashIcon className="h-5 w-5" />
                    </IconButton>
                  </ListItemSuffix>
                </ListItem>
              </motion.div>
            ))}
          </List>
        </CardBody>
      </Card>
    </div>
  );
};

export default FormBuilder;