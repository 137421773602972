import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Alert } from "@material-tailwind/react";
import Surveillance from './Surveillance';
import Gallery from './Gallery';
import PlantationProcess from './PlantationProcess';
import Fertigation from './Fertigation';
import Irrigation from './Irrigation';
import Soil from './Soil';
import Weather from './Weather';
import Environment from './Environment';
import CurrentConditions from './CurrentConditions';
import Forecasts from './Forecasts';
import { FiAlertCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';

const Dashboard = ({ theme }) => {
  const [columns, setColumns] = useState(4);
  const [showAlert, setShowAlert] = useState(true);
  const alerts = ["Intruder Detected in Zone 5", "10cm Rainfall in Zone 4.", "Plant Disease in Zone 3"];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) setColumns(1);
      else if (window.innerWidth < 768) setColumns(2);
      else if (window.innerWidth < 1024) setColumns(3);
      else setColumns(4);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    // Alert flashing effect
    const intervalId = setInterval(() => {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }, 10000);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(intervalId);
    };
  }, []);

  const gridComponents = [
    {
      title: "Surveillance",
      component: Surveillance,
      colSpan: { sm: 1, md: 2, lg: 2, xl: 2 },
      rowSpan: { sm: 1, md: 1, lg: 1, xl: 1 },
      height: { sm: 'auto', md: '300px', lg: '400px', xl: '500px' }
    },
    {
      title: "Irrigation",
      component: Irrigation,
      colSpan: { sm: 1, md: 2, lg: 2, xl: 2 },
      rowSpan: { sm: 1, md: 1, lg: 1, xl: 1 },
      height: { sm: 'auto', md: '300px', lg: '400px', xl: '500px' }
    },
    {
      title: "Plantation Process",
      component: PlantationProcess,
      colSpan: { sm: 1, md: 2, lg: 2, xl: 2 },
      rowSpan: { sm: 1, md: 1, lg: 1, xl: 1 },
      height: { sm: 'auto', md: '300px', lg: '400px', xl: '500px' }
    },
    {
      title: "Fertigation",
      component: Fertigation,
      colSpan: { sm: 1, md: 2, lg: 2, xl: 1 },
      rowSpan: { sm: 1, md: 1, lg: 1, xl: 1 },
      height: { sm: 'auto', md: '300px', lg: '400px', xl: '500px' }
    },
    {
      title: "Soil",
      component: Soil,
      colSpan: { sm: 1, md: 2, lg: 1, xl: 1 },
      rowSpan: { sm: 1, md: 1, lg: 1, xl: 1 },
      height: { sm: 'auto', md: '300px', lg: '400px', xl: '500px' }
    },
    {
      title: "Weather",
      component: Weather,
      colSpan: { sm: 1, md: 1, lg: 1, xl: 1 },
      rowSpan: { sm: 1, md: 1, lg: 1, xl: 1 },
      height: { sm: 'auto', md: '300px', lg: '400px', xl: '500px' }
    },
    {
      title: "Environment",
      component: Environment,
      colSpan: { sm: 1, md: 1, lg: 1, xl: 1 },
      rowSpan: { sm: 1, md: 1, lg: 1, xl: 1 },
      height: { sm: 'auto', md: '300px', lg: '400px', xl: '500px' }
    },
    {
      title: "Current Condition",
      component: CurrentConditions,
      colSpan: { sm: 1, md: 1, lg: 1, xl: 1 },
      rowSpan: { sm: 1, md: 1, lg: 1, xl: 1 },
      height: { sm: 'auto', md: '300px', lg: '400px', xl: '500px' }
    },
    {
      title: "Forecasts",
      component: Forecasts,
      colSpan: { sm: 1, md: 1, lg: 2, xl: 2 },
      rowSpan: { sm: 1, md: 1, lg: 1, xl: 1 },
      height: { sm: 'auto', md: '300px', lg: '400px', xl: '500px' }
    },
    {
      title: "Gallery",
      component: Gallery,
      colSpan: { sm: 1, md: 1, lg: 2, xl: 2 },
      rowSpan: { sm: 1, md: 1, lg: 1, xl: 1 },
      height: { sm: 'auto', md: '300px', lg: '400px', xl: '500px' }
    },
  ];

  const getResponsiveClasses = (item) => {
    const colSpanClasses = {
      sm: `sm:col-span-${item.colSpan.sm}`,
      md: `md:col-span-${item.colSpan.md}`,
      lg: `lg:col-span-${item.colSpan.lg}`,
      xl: `xl:col-span-${item.colSpan.xl}`,
    };

    const rowSpanClasses = {
      sm: `sm:row-span-${item.rowSpan.sm}`,
      md: `md:row-span-${item.rowSpan.md}`,
      lg: `lg:row-span-${item.rowSpan.lg}`,
      xl: `xl:row-span-${item.rowSpan.xl}`,
    };

    return `${colSpanClasses.sm} ${colSpanClasses.md} ${colSpanClasses.lg} ${colSpanClasses.xl} 
            ${rowSpanClasses.sm} ${rowSpanClasses.md} ${rowSpanClasses.lg} ${rowSpanClasses.xl}`;
  };

  const getResponsiveHeight = (item) => {
    return {
      height: item.height.sm,
      '@media (min-width: 768px)': { height: item.height.md },
      '@media (min-width: 1024px)': { height: item.height.lg },
      '@media (min-width: 1280px)': { height: item.height.xl },
    };
  };

  return (
    <>
      <div className="relative">
        {showAlert && (
          <div className="fixed top-16 right-6 z-50">
            <Alert
              icon={<FiAlertCircle />}
              color="red"
              animate={{
                mount: { y: 0 },
                unmount: { y: -100 },
              }}
            >
              {alerts[Math.floor(Math.random() * alerts.length)]}
            </Alert>
          </div>
        )}

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
        </motion.div>
        <div
          className="grid gap-4 auto-rows-auto"
          style={{
            gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
          }}
        >
          {gridComponents.map((item, index) => (
            <motion.div
              key={item.title}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className={`w-full ${getResponsiveClasses(item)}`}
              style={getResponsiveHeight(item)}
            >
              <div className="p-4">
                <item.component />
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Dashboard;