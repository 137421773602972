import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import {
  Navbar,
  Collapse,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
  EyeIcon,
  SunIcon,
  MoonIcon,
} from "@heroicons/react/24/outline";
import { useSpring, animated } from 'react-spring';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loginActions } from '../../redux/login-slice';
import logo from '../../Assets/Logo/logo.png'

function NavListMenu({ theme }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const menuItems = [
    {
      title: "Process Management",
      description: "Optimize your crop yield with AI-driven insights"
    },
    {
      title: "Soil Analysis",
      description: "Get detailed soil health reports and recommendations"
    },
    {
      title: "Weather Forecasting",
      description: "Accurate predictions to plan your farming activities"
    },
    {
      title: "Marketplace",
      description: "Connect with buyers and sellers in the agri-community"
    },
  ];

  const renderItems = menuItems.map(({ title, description }) => (
    <motion.a
      key={title}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}

    >
      <MenuItem className={`hover:bg-gradient-to-r from-green-400 to-blue-500 transition-all duration-300 ${theme === 'dark' ? 'text-white hover:text-white' : 'text-white hover:text-black'}`}>
        <Typography variant="h6" className="mb-1 font-bold">
          {title}
        </Typography>
        <Typography variant="small" className="font-normal">
          {description}
        </Typography>
      </MenuItem>
    </motion.a>
  ));

  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="small" className="font-normal">
            <ListItem
              className={`flex items-center gap-2 py-2 pr-4 font-bold ${isMenuOpen || isMobileMenuOpen
                ? theme === 'dark'
                  ? 'bg-green-700 text-black'
                  : 'bg-green-200 text-green-900'
                : theme === 'dark'
                  ? 'hover:bg-green-900 text-black'
                  : 'hover:bg-green-900 text-white'
                }`}
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              Services
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${isMenuOpen ? "rotate-180" : ""
                  }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${isMobileMenuOpen ? "rotate-180" : ""
                  }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className={`hidden max-w-screen-xl rounded-xl lg:block border-2 ${theme === 'dark' ? 'bg-gray-800 border-green-700' : 'bg-gray-800 border-green-200'}`}>
          <ul className="grid grid-cols-2 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}

function NavList({ theme }) {
  return (
    <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
      <Typography
        variant="small"
        className={`font-bold ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4 text-white">
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            Home
          </motion.div>
        </ListItem>
      </Typography>
      <NavListMenu theme={theme} />
      <Typography
        as="a"
        href="https://kiaanagrow.com"
        variant="small"
        className={`font-bold ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4 text-white">
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            About Us
          </motion.div>
        </ListItem>
      </Typography>
      <Typography
        as="a"
        href="/#contact"
        variant="small"
        className={`font-bold ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4 text-white">
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            Contact
          </motion.div>
        </ListItem>
      </Typography>
    </List>
  );
}

export default function EnhancedNavbar() {
  const { isLoggedIn } = useSelector((state) => state.login);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openNav, setOpenNav] = useState(false);
  const [theme, setTheme] = useState('light');

  const logoutHandler = () => {
    dispatch(loginActions.userLoggedInStatus({ status: false }));
    dispatch(loginActions.userLogout());
    dispatch(loginActions.unsetTenantId());
    dispatch(loginActions.unsetIsAdmin());
    navigate("/");
  };

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
    document.documentElement.classList.toggle('dark', theme === 'dark');
  }, [theme]);

  const logoSpring = useSpring({
    from: { opacity: 0, transform: 'scale(0.5)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { tension: 300, friction: 10 },
  });

  return (
    <Navbar className={`mx-auto max-w-full px-4 py-2 rounded-none border-none transition-all duration-300 ${theme === 'dark'
      ? 'bg-gradient-to-b from-black via-black to-black text-white'
      : 'bg-gradient-to-b from-black via-black to-black text-white'
      }`}>
      <div className="flex items-center justify-between">
        <Typography
          as="a"
          href="#"
          variant="h6"
          className="mr-4 cursor-pointer py-1.5 lg:ml-2"
        >
          <animated.div style={logoSpring} className="flex items-center gap-2">
            {/* <EyeIcon className={`h-8 w-8 ${theme === 'dark' ? 'text-green-300' : 'text-green-500'}`} /> */}
            {/* <img src={logo} alt="logo" className="h-7 w-14 pb-1" /> */}
            <span className="font-bold text-2xl bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent">
              Kiaaniot
            </span>
          </animated.div>
        </Typography>
        {!isLoggedIn &&
          <div className="hidden lg:block">
            <NavList theme={theme} />
          </div>}
        <div className="hidden gap-2 lg:flex">
          {isLoggedIn ? <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button variant="gradient" size="sm" className="bg-gradient-to-r from-green-400 to-blue-500" onClick={logoutHandler}>
              Log Out
            </Button>
          </motion.div> : <><motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button variant="text" size="sm" className={theme === 'dark' ? 'text-white' : 'text-white'}>
              <Link to="/login">
                Log In
              </Link>
            </Button>
          </motion.div>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Button variant="gradient" size="sm" className="bg-gradient-to-r from-green-400 to-blue-500">
                Sign Up
              </Button>
            </motion.div> </>}

          {/* <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <IconButton
              variant="text"
              className={theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}
              onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
            >
              {theme === 'dark' ? (
                <SunIcon className="h-6 w-6" />
              ) : (
                <MoonIcon className="h-6 w-6" />
              )}
            </IconButton>
          </motion.div> */}
        </div>
        <IconButton
          variant="text"
          className={`lg:hidden ${theme === 'dark' ? 'text-gray-700' : 'text-gray-700'}`}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6 text-white" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6 text-white" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        {!isLoggedIn &&
          <NavList theme={theme} />
        }
        <div className="flex w-full flex-nowrap items-center gap-2 lg:hidden">
          {isLoggedIn ?

            <Button variant="outlined" size="sm" className={`${theme === 'dark' ? 'text-white border-white' : 'text-white border-white'}`} fullWidth onClick={logoutHandler}>
              Logout
            </Button> :
            <>
              <Button variant="outlined" size="sm" className={`${theme === 'dark' ? 'text-white border-white' : 'text-white border-white'}`} fullWidth>
                <Link to="/login">
                  Log In
                </Link>
              </Button>
              <Button variant="gradient" size="sm" fullWidth className="bg-gradient-to-r from-green-400 to-blue-500">
                Sign Up
              </Button>
            </>

          }
          {/* <IconButton
            variant="text"
            className={theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}
            onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
          >
            {theme === 'dark' ? (
              <SunIcon className="h-6 w-6" />
            ) : (
              <MoonIcon className="h-6 w-6" />
            )}
          </IconButton> */}
        </div>
      </Collapse>
    </Navbar>
  );
}

export { EnhancedNavbar };