import EnhancedNavbar from '../Components/Navbar/Navbar';
import Hero from './Hero';
import SaaSProduct from './SaaSProduct';
import Partners from './Partners';
import Services from './Services';
import Features from './Features';
import Pricing from './Pricing';
import Testimonials from './Testimonials';
import FAQ from './FAQ';
import Contact from './Contact';
import Footer from './Footer';

const LandingPage = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-green-400 to-blue-500 animate-gradient"> {/* animate-gradient */}
      <header>
        <EnhancedNavbar />
        <Hero />
      </header>

      <main className="flex-grow">

        <section id="saas-product">
          <SaaSProduct />
        </section>

        {/* <section id="partners">
          <Partners />
        </section> */}

        <section id="services">
          <Services />
        </section>

        <section id="features">
          <Features />
        </section>

        <section id="pricing">
          <Pricing />
        </section>

        {/* <section id="testimonials">
          <Testimonials />
        </section> */}

        <section id="faq">
          <FAQ />
        </section>

        <section id="contact">
          <Contact />
        </section>
      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default LandingPage;
