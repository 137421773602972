import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSpring, animated } from 'react-spring';
import { FaSun, FaCloudSunRain, FaCloudMoonRain, FaThermometerHalf, FaSpinner } from 'react-icons/fa';
import { useSelector } from "react-redux";
import { GET } from '../utils/ApiHandler';

const CurrentConditions = () => {
  const { token } = useSelector((state) => state.login);
  const [isLoading, setIsLoading] = useState(true);
  const [currcond, setCurrentConditionsData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const getCurrentConditionsData = async () => {
      try {
        const envData = await GET(`${process.env.REACT_APP_API_URL}/api/v1/currentconditions/currentConditionsData`, token);
        if (envData && envData.data) {
          setCurrentConditionsData(envData.data.currentConditionsData[0]);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        console.error("Error fetching data:", e);
        setIsLoading(false);
      }
    };
    getCurrentConditionsData();
  }, [token]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isHovered) {
        setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
      }
    }, 3000);
    return () => clearInterval(timer);
  }, [isHovered]);

  const springProps = useSpring({
    scale: isHovered ? 1.05 : 1,
    config: { tension: 300, friction: 10 },
  });

  const getConditionsData = () => {
    if (isLoading) {
      return [
        { name: 'Loading', value: 'Please wait', unit: '', icon: FaSpinner, color: 'from-gray-400 to-gray-600' },
        { name: 'Loading', value: 'Please wait', unit: '', icon: FaSpinner, color: 'from-gray-400 to-gray-600' },
        { name: 'Loading', value: 'Please wait', unit: '', icon: FaSpinner, color: 'from-gray-400 to-gray-600' },
        { name: 'Loading', value: 'Please wait', unit: '', icon: FaSpinner, color: 'from-gray-400 to-gray-600' },
      ];
    }
    if (!currcond) return [];

    return [
      { name: 'Current Weather', value: currcond.WeatherText, unit: '', icon: FaCloudSunRain, color: 'from-blue-400 to-blue-600' },
      { name: 'Precipitation', value: currcond.HasPrecipitation ? 'Yes' : 'No', unit: '', icon: FaCloudMoonRain, color: 'from-green-400 to-green-600' },
      { name: 'Day Time', value: currcond.IsDayTime ? 'Yes' : 'No', unit: '', icon: FaSun, color: 'from-red-400 to-red-600' },
      { name: 'Temperature', value: currcond.Temperature.Metric.Value, unit: '°C', icon: FaThermometerHalf, color: 'from-teal-400 to-teal-600' },
    ];
  };

  return (
    <animated.div
      style={springProps}
      className="w-full max-w-md mx-auto bg-gradient-to-br from-green-50 to-blue-50 rounded-xl shadow-2xl overflow-hidden"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="p-6 space-y-4">
        <h2 className="text-2xl font-bold text-green-800">CurrentCondition</h2>

        {currcond ? (
          <div className="relative h-48">
            <AnimatePresence>
              {getConditionsData().map((data, index) => (
                index === activeIndex && (
                  <motion.div
                    key={data.name}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                    className="absolute inset-0 flex flex-col items-center justify-center"
                  >
                    <div className={`w-24 h-24 rounded-full bg-gradient-to-br ${data.color} flex items-center justify-center mb-4`}>
                      <data.icon className="text-4xl text-white" />
                    </div>
                    <h3 className="text-xl font-semibold text-gray-800">{data.name}</h3>
                    <p className="text-3xl font-bold text-green-600">
                      {data.value} {data.unit}
                    </p>
                  </motion.div>
                )
              ))}
            </AnimatePresence>
          </div>
        ) : (
          <div className="h-48 flex items-center justify-center">
            <p className="text-xl font-semibold text-gray-600">No Data Available</p>
          </div>
        )}

        {!isLoading && currcond && (
          <div className="flex justify-center space-x-2">
            {getConditionsData().map((_, index) => (
              <button
                key={index}
                className={`w-3 h-3 rounded-full ${index === activeIndex ? 'bg-green-500' : 'bg-gray-300'}`}
                onClick={() => setActiveIndex(index)}
              />
            ))}
          </div>
        )}
      </div>
      <div className="bg-gradient-to-r from-green-400 to-blue-500 p-4">
        <p className="text-sm text-white text-center">
          Real-time Current Conditions data for actionable decisions
        </p>
      </div>
    </animated.div>
  );
};

export default CurrentConditions;