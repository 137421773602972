import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  Typography,
  Button,
  Stepper,
  Step,
  Spinner,
  Alert,
} from "@material-tailwind/react";
import { motion, AnimatePresence } from 'framer-motion';

const HEADINGS = ['Land Acquisition', 'Climatic Conditions', 'Fencing and Basic Infrastructure', 'Legal Opinion', 'Sample Test'];

const FilePreview = ({ file }) => {
  const src = file.sasUrl || file.src;

  if (src.includes('.mp4') || src.includes('.mpeg')) {
    return (
      <div className="w-full max-w-sm m-2">
        <video src={src} controls className="w-full h-full object-cover rounded-lg shadow-lg"></video>
      </div>
    );
  } else if (src.includes('.pdf')) {
    return (
      <div className="w-full max-w-sm m-2">
        <iframe src={src} className="w-full h-48 rounded-lg shadow-lg" title={`pdf-preview`}></iframe>
        <div className="text-center mt-2">
          <Button color="blue" size="sm" ripple="light" href={src} download={`document.pdf`} target="_blank">
            Download PDF
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-full max-w-sm m-2">
        <img src={src} alt="" className="w-full h-full object-cover rounded-lg shadow-lg" />
      </div>
    );
  }
};

const FormData = () => {
  const navigate = useNavigate();
  const { tenantId, isAdmin } = useSelector((state) => state.login);
  const [questions, setQuestions] = useState([]);
  const [latestSubmission, setLatestSubmission] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    fetchQuestionsAndAnswers();
  }, []);

  const fetchQuestionsAndAnswers = async () => {
    try {
      setLoading(true);
      const questionsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/questions`);
      setQuestions(questionsResponse.data || []);

      const submissionResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/submissions/latest?tenantId=${tenantId}`);
      setLatestSubmission(submissionResponse.data || []);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const getAnswerForQuestion = (questionId) => {
    if (!latestSubmission || !latestSubmission.answers) return 'No answer submitted';
    const answer = latestSubmission.answers.find(a => a.question?._id === questionId);
    if (!answer) return 'No answer submitted';

    if (answer.question.type === 'checkbox') {
      return answer.answer === 'Yes' ? 'Yes' : 'No';
    }
    return answer.answer;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner className="h-12 w-12" color="blue" />
      </div>
    );
  }

  const groupedQuestions = questions.reduce((acc, question) => {
    if (!acc[question.heading]) acc[question.heading] = {};
    if (!acc[question.heading][question.subheading]) acc[question.heading][question.subheading] = [];
    acc[question.heading][question.subheading].push(question);
    return acc;
  }, {});

  const renderQuestionsForHeading = (heading) => {
    return Object.keys(groupedQuestions[heading] || {}).map(subheading => (
      <motion.div
        key={subheading}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.5 }}
        className="mt-6 mb-8"
      >
        <Typography variant="h5" color="blue-gray" className="mb-4">{subheading}</Typography>
        {groupedQuestions[heading][subheading].map((question) => (
          <Card key={question._id} className="mb-4 overflow-hidden">
            <CardBody>
              <Typography variant="h6" color="blue-gray" className="mb-2">{question.text}</Typography>
              <Typography color="gray" className="mb-4">{getAnswerForQuestion(question._id)}</Typography>
              {question.type === 'files' && (
                <div>
                  <Typography variant="small" color="blue-gray" className="font-medium">Files:</Typography>
                  <div className="flex flex-wrap justify-start">
                    {latestSubmission.answers && latestSubmission.answers.find(a => a.question?._id === question._id)?.files ?
                      latestSubmission.answers.find(a => a.question._id === question._id).files.map((file, index) => (
                        <FilePreview key={index} file={file} />
                      ))
                      : 'N/A'}
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        ))}
      </motion.div>
    ));
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="mt-6">
        <CardBody>
          <Typography variant="h3" color="blue-gray" className="mb-4">
            Tenant Profile
          </Typography>
          {isAdmin && (
            <Button
              color="blue"
              ripple="light"
              onClick={() => { navigate("/admin/onboardtenant/formbuilder") }}
              className="mb-4"
            >
              Data Form Builder
            </Button>
          )}
          {latestSubmission && Object.keys(latestSubmission).length > 0 ? (
            <>
              <Typography color="gray" className="mb-6">
                Last updated: {new Date(latestSubmission.submittedAt).toLocaleString()}
              </Typography>

              <div className="w-full py-4 px-8">
                <Stepper
                  activeStep={activeStep}
                  lineClassName="bg-blue-gray-50"
                >
                  {HEADINGS.map((label, index) => (
                    <Step
                      key={label}
                      onClick={() => setActiveStep(index)}
                      className="cursor-pointer"
                    >
                      <div className="absolute -bottom-[2rem] w-max text-center">
                        <Typography
                          variant="small"
                          color={activeStep === index ? "blue-gray" : "gray"}
                          className="font-normal"
                        >
                          {label}
                        </Typography>
                      </div>
                    </Step>
                  ))}
                </Stepper>
              </div>

              <AnimatePresence mode="wait">
                {renderQuestionsForHeading(HEADINGS[activeStep])}
              </AnimatePresence>

              <div className="flex justify-between mt-8">
                <Button
                  color="blue-gray"
                  buttonType="link"
                  size="lg"
                  ripple="dark"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Back
                </Button>
                {activeStep < HEADINGS.length - 1 && (
                  <Button
                    color="blue"
                    size="lg"
                    ripple="light"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}
              </div>
            </>
          ) : (
            <Alert color="blue" className="mt-6">No submissions available.</Alert>
          )}
          {error && (
            <Alert color="red" className="mt-6">{error}</Alert>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default FormData;