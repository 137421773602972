import React from 'react';
import {  Typography } from "@material-tailwind/react";
import ControlPanel from './ControlPanel';
import AIInsights from './AIInsights';
import IoTSensorReadings from './IoTSensorReadings';
import DownloadReports from './DownloadReports';
import IrrigationHistory from './IrrigationHistory';
import EnergyConsumption from './EnergyConsumption';
import FertigationHistory from './FertigationHistory';
import SoilProfilingReport from './SoilProfiling';
import IrrigationSchedule from './IrrigationSchedule';
import FertigationSchedule from './FertigationSchedule';
import ThreeDPlotMap from './ThreeDPlotMap';
const IrrigationModule = () => {
  return (
    <div className="container mx-auto p-4">
      <Typography variant="h2" color="blue-gray" className="mb-4">
        Irrigation and Fertigation Module
      </Typography>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="lg:col-span-2">
          <ThreeDPlotMap />
        </div>
        {/* <div className="lg:col-span-2">
            <Irri />
          </div> */}
        <div className="lg:col-span-1">
          <ControlPanel />
        </div>
        <div className="lg:col-span-1">
          <AIInsights />
        </div>
        <div className="lg:col-span-1">
          <IoTSensorReadings />
        </div>
        <div className="lg:col-span-1">
          <DownloadReports />
        </div>
        <div className="lg:col-span-2">
          <IrrigationHistory />
        </div>
        <div>
          <EnergyConsumption />
        </div>
        <div className="lg:col-span-2">
          <FertigationHistory />
        </div>
        <div className="lg:col-span-1">
          <SoilProfilingReport />
        </div>
        <div className="lg:col-span-3">
          <IrrigationSchedule />
        </div>
        <div className="lg:col-span-3">
          <FertigationSchedule />
        </div>
      </div>
    </div>
  );
};

export default IrrigationModule;