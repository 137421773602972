import React, { useState, useEffect } from 'react';
import { Card, CardBody, Typography } from "@material-tailwind/react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const PlatformViewsChart = () => {
  const [data, setData] = useState([
    { name: 'M', value: 50 },
    { name: 'T', value: 20 },
    { name: 'W', value: 10 },
    { name: 'T', value: 22 },
    { name: 'F', value: 50 },
    { name: 'S', value: 10 },
    { name: 'S', value: 40 },
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setData(prevData =>
        prevData.map(item => ({
          ...item,
          value: Math.max(0, item.value + Math.floor(Math.random() * 20) - 10)
        }))
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Card className="overflow-hidden">
      <CardBody>
        <Typography variant="h6" color="blue-gray" className="mb-2">
          Platform Views
        </Typography>
        <Typography variant="small" color="gray" className="mb-4">
          Last Campaign Performance
        </Typography>
        <div className="h-72">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#3B82F6" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <Typography variant="small" color="gray" className="mt-4">
          campaign sent 2 days ago
        </Typography>
      </CardBody>
    </Card>
  );
};

export default PlatformViewsChart;