import React, { useState, useEffect } from 'react';
import { Card, CardBody, Typography } from "@material-tailwind/react";
import ReactApexChart from 'react-apexcharts';

const CompletedTasksChart = () => {
  const [series, setSeries] = useState([{
    name: 'Completed Tasks',
    data: [50, 40, 300, 220, 500, 250, 400, 230, 500]
  }]);

  const options = {
    chart: {
      height: 300,
      type: 'area',
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      categories: ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
    },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSeries([{
        name: 'Completed Tasks',
        data: series[0].data.map(value =>
          Math.max(0, value + Math.floor(Math.random() * 100) - 50)
        )
      }]);
    }, 5000);

    return () => clearInterval(interval);
  }, [series]);

  return (
    <Card className="overflow-hidden">
      <CardBody>
        <Typography variant="h6" color="blue-gray" className="mb-2">
          Completed Plantation
        </Typography>
        <Typography variant="small" color="gray" className="mb-4">
          Last Plantation Performance
        </Typography>
        <ReactApexChart options={options} series={series} type="area" height={300} />
        <Typography variant="small" color="gray" className="mt-4">
          just updated
        </Typography>
      </CardBody>
    </Card>
  );
};

export default CompletedTasksChart;