import React from 'react';
import {
  Card,
  Typography,
  Button,
} from "@material-tailwind/react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import s1 from '../Assets/Services/s1.jpg';
import s2 from '../Assets/Services/s2.jpg';
import s3 from '../Assets/Services/s3.jpg';

const ServicesSection = ({ title, description, imageSrc, isReversed }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const contentVariants = {
    hidden: { opacity: 0, x: isReversed ? -50 : 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8, ease: "easeOut" } },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8, ease: "easeOut" } },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      className={`flex flex-col ${isReversed ? 'lg:flex-row-reverse' : 'lg:flex-row'} items-center mb-20`}
    >
      <motion.div
        variants={imageVariants}
        className="w-full lg:w-1/2 mb-8 lg:mb-0"
      >
        <Card className="overflow-hidden shadow-xl rounded-xl transform hover:scale-105 transition-transform duration-300">
          <img
            src={imageSrc}
            alt={title}
            className="w-full h-64 object-cover object-center"
          />
        </Card>
      </motion.div>
      <motion.div
        variants={contentVariants}
        className={`w-full lg:w-1/2 ${isReversed ? 'lg:pr-12' : 'lg:pl-12'}`}
      >
        <Typography variant="h3" color="blue-gray" className="mb-4">
          {title}
        </Typography>
        <Typography variant="paragraph" color="white" className="mb-6">
          {description}
        </Typography>
        <Button
          size="lg"
          color="black"
          variant="gradient"
          ripple="light"
          className="rounded-full shadow-lg hover:shadow-green-500/50 transition-all duration-300"
        >
          <a href="/#features">
          Learn More
          </a>
        </Button>
      </motion.div>
    </motion.div>
  );
};

const Services = () => {
  const features = [
    {
      title: "AI-Powered Crop Analysis",
      description: "Our cutting-edge AI algorithms analyze real-time data from your fields, providing actionable insights to optimize crop health, predict yields, and mitigate risks. Stay ahead of the curve with precision agriculture that adapts to your farm's unique needs.",
      imageSrc: s3,
    },
    {
      title: "IoT Sensor Integration",
      description: "Seamlessly connect and monitor your entire farm ecosystem with our state-of-the-art IoT sensors. From soil moisture levels to weather conditions, get real-time data at your fingertips. Make informed decisions that drive efficiency and sustainability in your operations.",
      imageSrc: s1,
    },
    {
      title: "Irrigation and Fertigation Automation",
      description: "Optimize your water and nutrient management with our irrigation and fertigation automation feature. Effortlessly control and schedule your irrigation systems to ensure your crops receive the right amount of water at the right time. With integrated fertigation capabilities, you can deliver precise nutrients directly to your plants, enhancing growth and yield.",
      imageSrc: s2,
    },
  ];

  return (
    <section className="py-20">
      <div className="container mx-auto px-4">
        <Typography variant="h2" color="blue-gray" className="text-center mb-12 font-bold">
          Revolutionary AgriTech Features
        </Typography>
        {features.map((feature, index) => (
          <ServicesSection
            key={index}
            title={feature.title}
            description={feature.description}
            imageSrc={feature.imageSrc}
            isReversed={index % 2 !== 0}
          />
        ))}
      </div>
    </section>
  );
};

export default Services;