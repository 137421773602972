import React from 'react';
import { Typography, Button } from '@material-tailwind/react';
import { FaInstagram, FaAppStore, FaGooglePlay, FaLinkedin } from 'react-icons/fa';

const footerLinks = [
  {
    title: "About Us",
    link: "https://kiaanagrow.com",
  },
  {
    title: "Careers",
    link: "https://kiaanagrow.com",
  },
  {
    title: "Pricing",
    link: "/#pricing",
  }
]

const socialMedia = [
  {
    icon:FaInstagram,
    link: "https://www.instagram.com/kiaan.agrowtech?igsh=cDJsMmZqOThleWxx"
  },
  {
    icon: FaLinkedin,
    link: "https://www.linkedin.com/company/kiaanagrowtech/posts/?feedView=all"
  }
]

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-center">
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <Typography variant="h5" className="font-bold mb-2">
              Kiaaniot
            </Typography>
            <Typography variant="small" className="opacity-60">
              AgriTech AI-IOT Platform
            </Typography>
          </div>
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <ul className="flex flex-wrap justify-center md:justify-start">
              {footerLinks.map((item) => (
                <li key={item} className="mr-6 mb-2">
                  <Typography
                    as="a"
                    href={item.link}
                    variant="small"
                    className="opacity-60 hover:opacity-100 transition-opacity"
                  >
                    {item.title}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full md:w-1/3 flex flex-col items-center md:items-end">
            <Typography variant="h6" className="mb-4">
              Get the App
            </Typography>
            <div className="flex flex-col space-y-2">
              <Button
                size="sm"
                className="flex items-center justify-center bg-white text-black"
                fullWidth
              >
                <FaGooglePlay className="mr-2 h-6 w-6" />
                GET IT ON Google Play
              </Button>
              <Button
                size="sm"
                className="flex items-center justify-center bg-white text-black"
                fullWidth
              >
                <FaAppStore className="mr-2 h-6 w-6" />
                Download on the App Store
              </Button>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-700 mt-8 pt-8 flex flex-wrap justify-between items-center">
          <Typography variant="small" className="opacity-60">
            © 2024 Kiaaniot™. All Rights Reserved.
          </Typography>
          <div className="flex space-x-4 mt-4 md:mt-0">
            {socialMedia.map((item) => (
              <a key={item.link} href={item.link} target="_blank" rel="noopener noreferrer">
                <item.icon className="h-6 w-6 text-white" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;