import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { motion } from "framer-motion";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    Button,
    Typography,
    Alert,
    Textarea,
} from "@material-tailwind/react";
import {
    PencilSquareIcon,
    CalendarIcon,
} from "@heroicons/react/24/solid";
import { GET, PUT, POST } from '../../utils/ApiHandler';
import dayjs from 'dayjs';

const ProcessForm = () => {
    const navigate = useNavigate();
    const { token, tenantId } = useSelector((state) => state.login);
    const { processId } = useParams();
    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [projectStartDate, setProjectStartDate] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', color: 'green' });

    const [formData, setFormData] = useState({
        title: '',
        subtitle: '',
        week_number: '',
        process_week_range: '',
        instruction: '',
        description: '',

    });

    useEffect(() => {
        if (processId) {
            const getProcess = async () => {
                let response = await GET(`${process.env.REACT_APP_API_URL}/api/v1/process/${processId}`, token);
                let processObj = response.data.process;
                setFormData(processObj);
            };
            getProcess();
        }
    }, [token, processId]);


    useEffect(() => {
        const getProjectStartDate = async () => {
            let data = await GET(`${process.env.REACT_APP_API_URL}/api/v1/tenants/list?tenantId=${tenantId}`, token);
            if (data?.data?.tenants.length > 0) {
                setProjectStartDate(data?.data?.tenants[0].project_start_date);
            }
        };
        getProjectStartDate();
    }, [token, tenantId]);

    const processSubmitHandler = async (e) => {
        e.preventDefault();
        let data;
        if (processId) {
            data = await PUT(`${process.env.REACT_APP_API_URL}/api/v1/process/${processId}`, formData, token);
        } else {
            data = await POST(`${process.env.REACT_APP_API_URL}/api/v1/process/create`, formData, token);
        }
        if (data.success) {
            setAlert({
                show: true,
                message: `Successfully ${processId ? 'updated' : 'added'} Process!`,
                color: 'green'
            });
            setTimeout(() => navigate('/admin/process'), 2000);
        } else {
            setAlert({
                show: true,
                message: `Error ${processId ? 'updating' : 'adding'} Process: ${data.message}`,
                color: 'red'
            });
        }
    };


    const cancelHandler = () => {
        navigate('/admin/process');
    };

    const textInputHandler = (id, value) => {
        if (id === "week_number") {
            const weekStart = dayjs(projectStartDate).add((value - 1) * 7, 'day');
            const weekEnd = weekStart.add(6, 'day');
            const weekRange = `${weekStart.format('MMM DD, YYYY')} - ${weekEnd.format('MMM DD, YYYY')}`;

            setFormData((prevFormData) => ({
                ...prevFormData,
                [id]: value,
                process_week_range: weekRange,
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [id]: value,
            }));
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="min-h-screen p-8 bg-gradient-to-br from-green-400 to-blue-500 flex items-center justify-center rounded-xl"
        >
            <Card className="w-full max-w-[50rem] bg-white bg-opacity-90 backdrop-blur-md">
                <CardHeader
                    color="blue"
                    floated={false}
                    shadow={false}
                    className="m-0 grid place-items-center rounded-b-none py-8 px-4 text-center"
                >
                    <div className="mb-4 rounded-full border border-white/10 bg-white/10 p-6 text-white">
                        <PencilSquareIcon className="h-10 w-10" />
                    </div>
                    <Typography variant="h4" color="white">
                        {processId ? 'Edit' : 'Add'} Plantation Process
                    </Typography>
                </CardHeader>
                <CardBody className="flex flex-col gap-4">
                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.1 }}
                    >
                        <Input
                            label="Title"
                            size="lg"
                            icon={<PencilSquareIcon className="h-5 w-5" />}
                            value={formData.title}
                            onChange={(e) => textInputHandler('title', e.target.value)}
                        />
                    </motion.div>
                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.2 }}
                    >
                        <Input
                            label="Subtitle"
                            size="lg"
                            icon={<PencilSquareIcon className="h-5 w-5" />}
                            value={formData.subtitle}
                            onChange={(e) => textInputHandler('subtitle', e.target.value)}
                        />
                    </motion.div>
                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.3 }}
                    >
                        <Input
                            label="Week Number"
                            size="lg"
                            icon={<CalendarIcon className="h-5 w-5" />}
                            type="number"
                            value={formData.week_number}
                            onChange={(e) => textInputHandler('week_number', e.target.value)}
                        />
                    </motion.div>
                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.4 }}
                    >
                        <Input
                            label="Week Range"
                            size="lg"
                            icon={<CalendarIcon className="h-5 w-5" />}
                            value={formData.process_week_range}
                            readOnly
                        />
                    </motion.div>
                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.7 }}
                    >
                        <Input
                            label="Instruction"
                            size="lg"
                            value={formData.instruction}
                            onChange={(e) => textInputHandler('instruction', e.target.value)}
                        />
                    </motion.div>
                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.7 }}
                    >
                        <Textarea
                            label="Description"
                            size="lg"
                            value={formData.description}
                            onChange={(e) => textInputHandler('description', e.target.value)}
                        />
                    </motion.div>
                </CardBody>
                <CardFooter className="pt-0">
                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                    >
                        <Button
                            variant="gradient"
                            color="green"
                            fullWidth
                            onClick={processSubmitHandler}
                        >
                            {processId ? 'Update' : 'Save'}
                        </Button>
                    </motion.div>
                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        className="mt-4"
                    >
                        <Button
                            variant="outlined"
                            color="red"
                            fullWidth
                            onClick={cancelHandler}
                        >
                            Cancel
                        </Button>
                    </motion.div>
                </CardFooter>
            </Card>
            {alert.show && (
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    className="fixed top-4 right-4 z-50"
                >
                    <Alert
                        color={alert.color}
                        dismissible={{
                            onClose: () => setAlert({ ...alert, show: false }),
                        }}
                    >
                        {alert.message}
                    </Alert>
                </motion.div>
            )}
            {/* {formData.media.length > 0 && (
                <Card className="mt-8 w-full max-w-[50rem] bg-white bg-opacity-90 backdrop-blur-md">
                    <CardHeader
                        color="blue"
                        floated={false}
                        shadow={false}
                        className="m-0 grid place-items-center rounded-b-none py-8 px-4 text-center"
                    >
                        <Typography variant="h5" color="white">
                            Gallery
                        </Typography>
                    </CardHeader>
                    <CardBody>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                            {formData.media.map((media, i) => (
                                <div key={i} className="relative">
                                    <Button
                                        color="red"
                                        size="sm"
                                        className="!absolute top-2 right-2 rounded-full p-2"
                                        onClick={() => fileDeleteHandler(i, "media")}
                                    >
                                        <XCircleIcon className="h-5 w-5" />
                                    </Button>
                                    {media.sasUrl.includes('.mp4') || media.sasUrl.includes('.mpeg') ? (
                                        <video src={media.sasUrl} controls className="w-full h-48 object-cover rounded-lg" />
                                    ) : (
                                        <img src={media.sasUrl} alt="" className="w-full h-48 object-cover rounded-lg" />
                                    )}
                                </div>
                            ))}
                        </div>
                    </CardBody>
                </Card>
            )} */}
        </motion.div>
    );
};

export default ProcessForm;