import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { POST } from "../../utils/ApiHandler";
import { Card, CardBody, CardFooter, Typography, Input, Button, Spinner } from "@material-tailwind/react";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/solid";

const ChatMessage = ({ message, isUser }) => (
  <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-4`}>
    <div className={`max-w-[70%] p-3 rounded-xl ${isUser ? 'bg-green-100' : 'bg-blue-100'}`}>
      <Typography>{message}</Typography>
    </div>
  </div>
);

const KiaanGPT = () => {
  const { token } = useSelector((state) => state.login);
  const [input, setInput] = useState('');
  const [conversation, setConversation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [conversation]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage = { text: input, isUser: true };
    setConversation([...conversation, userMessage]);
    setInput('');
    setIsLoading(true);

    try {
      const body = { prompt: input };
      const response = await POST(`${process.env.REACT_APP_API_URL}/api/v1/kiaanagrowgpt`, body, token);
      const data = response.data.gptResponse;
      const gptMessage = { text: data, isUser: false };
      setConversation((prev) => [...prev, gptMessage]);
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = { text: 'Sorry, there was an error processing your request.', isUser: false };
      setConversation((prev) => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-green-400 to-blue-500 rounded-xl">
      <div className="bg-white bg-opacity-10 p-4 shadow-md">
        <Typography variant="h4" color="white" className="text-center font-bold">
          KiaanAgrowGPT
        </Typography>
      </div>
      <div className="flex-grow container mx-auto px-4 py-8">
        <Card className="w-full max-w-4xl mx-auto">
          <CardBody className="h-[70vh] overflow-y-auto p-6 rounded-xl">
            {conversation.map((msg, index) => (
              <ChatMessage key={index} message={msg.text} isUser={msg.isUser} />
            ))}
            <div ref={messagesEndRef} />
          </CardBody>
          <CardFooter className="p-4">
            <form onSubmit={handleSubmit} className="flex items-center gap-2">
              <Input
                type="text"
                placeholder="Type your message here..."
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className="flex-grow"
                containerProps={{
                  className: "min-w-0",
                }}
                disabled={isLoading}
              />
              <Button
                type="submit"
                disabled={isLoading}
                className="flex items-center gap-2 bg-green-500 hover:bg-green-600 transition-all duration-300"
              >
                {isLoading ? (
                  <Spinner className="h-4 w-4" />
                ) : (
                  <ChevronDoubleRightIcon className="h-5 w-5" />
                )}
              </Button>
            </form>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default KiaanGPT;