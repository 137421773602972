import React from 'react';
import { Typography } from "@material-tailwind/react";
import DataTable from '../DataTable/DataTable';
import { GET, DELETE } from '../../utils/ApiHandler';
import { useSelector } from 'react-redux';

const ProcessList = () => {
  const TABLE_HEAD = ["Week Number", "Week Range", "Title", "Actions"];
  const { token, tenantId } = useSelector((state) => state.login);

  const fetchProcesses = async () => {
    const data = await GET(`${process.env.REACT_APP_API_URL}/api/v1/process/list`, token);
    return data?.data?.process || [];
  };

  const deleteProcess = async (processId) => {
    return await DELETE(`${process.env.REACT_APP_API_URL}/api/v1/process/delete?tenantId=${tenantId}`, { processId: processId }, token);
  };

  const renderProcessRow = (process) => (
    <>
      <td className="p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          {process.week_number}
        </Typography>
      </td>
      <td className="p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          {process.process_week_range}
        </Typography>
      </td>
      <td className="p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          {process.title}
        </Typography>
      </td>
      {/* <td className="p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          {process.subCategoryId}
        </Typography>
      </td> */}
    </>
  );

  return (
    <DataTable
      title="Process"
      subtitle="Manage your Process"
      addButtonText="Add Process"
      addButtonLink="/admin/process/add"
      tableHead={TABLE_HEAD}
      fetchData={fetchProcesses}
      deleteItem={deleteProcess}
      renderTableRow={renderProcessRow}
      searchFields={['process_week_range', 'week_number']}
    />
  );
};

export default ProcessList;