import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Card,
  CardBody,
  Typography,
  Tabs,
  TabsHeader,
  Tab,
  TabsBody,
  TabPanel,
} from "@material-tailwind/react";
import { motion } from "framer-motion";
import { GET } from '../utils/ApiHandler';

const EnvironmentHistory = () => {
  const [activeTab, setActiveTab] = useState("daily");
  const [weatherData, setWeatherData] = useState([]);

  useEffect(() => {
    fetchWeatherData();
  }, []);

  const fetchWeatherData = async () => {
    try {
      const response = await GET(`${process.env.REACT_APP_API_URL}/api/v1/iot/iotData`);
      setWeatherData(response.data.iotData);
    } catch (error) {
      console.error("Error fetching weather data:", error);
    }
  };

  const processDataForPeriod = (data, period) => {
    const currentDate = new Date();
    let startDate = new Date(currentDate);
    let filteredData = [];

    switch (period) {
      case 'daily':
        startDate.setHours(0, 0, 0, 0);
        filteredData = data.filter(item => new Date(item.createdAt) >= startDate);
        break;
      case 'weekly':
        startDate.setDate(currentDate.getDate() - 7);
        filteredData = data.filter(item => new Date(item.createdAt) >= startDate);
        break;
      case 'monthly':
        startDate.setMonth(currentDate.getMonth() - 1);
        filteredData = data.filter(item => new Date(item.createdAt) >= startDate);
        break;
    }

    return filteredData.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  };

  const prepareChartData = (data, field) => {
    if (data.length === 0) return { data: [], min: 0, max: 40 };

    const values = data.map(item => {
      switch (field) {
        case 'lux':
          return parseFloat(item.lux.latestReading);
        case 'solarIntensity':
          return parseFloat(item.solarIntensity.latestReading);
        case 'windSpeed':
          return parseFloat(item.windSpeed);
        case 'windDirection':
          return parseFloat(item.windDirection);
        default:
          return 0;
      }
    });

    const filteredValues = values.filter(value => !isNaN(value));
    const min = Math.min(...filteredValues);
    const max = Math.max(...filteredValues);

    return {
      data: data.map(item => {
        let value;
        switch (field) {
          case 'lux':
            value = parseFloat(item.lux.latestReading);
            break;
          case 'solarIntensity':
            value = parseFloat(item.solarIntensity.latestReading);
            break;
          case 'windSpeed':
            value = parseFloat(item.windSpeed);
            break;
          case 'windDirection':
            value = parseFloat(item.windDirection);
            break;
          default:
            value = 0;
        }
        return {
          x: new Date(item.createdAt.$date || item.createdAt).getTime(),
          y: isNaN(value) ? null : value
        };
      }),
      min: Math.max(0, min - (max - min) * 0.1),
      max: max + (max - min) * 0.1
    };
  };

  const apexOptions = (title, data, period) => {
    const currentDate = new Date();
    let startDate = new Date(currentDate);
    let endDate;

    switch (period) {
      case 'daily':
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(currentDate);
        endDate.setHours(24, 0, 0, 0);
        break;
      case 'weekly':
        startDate.setDate(currentDate.getDate() - 7);
        break;
      case 'monthly':
        startDate.setMonth(currentDate.getMonth() - 1);
        break;
    }

    return {
      chart: {
        height: 350,
        type: "line",
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
        },
      },
      colors: ["#4ade80"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: title,
        align: "left",
      },
      xaxis: {
        type: "datetime",
        min: startDate.getTime(),
        max: period === 'daily' ? endDate.getTime() : currentDate.getTime(),
        tickAmount: period === 'daily' ? 24 : undefined,
        labels: {
          formatter: function (val, timestamp) {
            return period === 'daily'
              ? new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
              : new Date(timestamp).toLocaleDateString();
          }
        }
      },
      yaxis: {
        title: {
          text: title,
        },
        min: data.min,
        max: data.max
      },
    };
  };

  const currentData = processDataForPeriod(weatherData, activeTab);

  const latestLux = currentData.length ? currentData[currentData.length - 1].lux.latestReading : null;
  const latestSolarIntensity = currentData.length ? currentData[currentData.length - 1].solarIntensity.latestReading : null;
  const latestWindSpeed = currentData.length ? currentData[currentData.length - 1].windSpeed : null;
  // const latestWindDirection = currentData.length ? currentData[currentData.length - 1].windDirection : null;

  // Highcharts options
  const highchartsOptions = {
    chart: {
      type: "line",
    },
    title: {
      text: "Wind Speed",
    },
    xAxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: "Wind Speed",
      },
    },
    series: [
      {
        name: "Wind Speed",
        data: currentData.map((item) => [
          new Date(item.createdAt).getTime(),
          parseFloat(item.windSpeed),
        ]),
      },
    ],
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h2" color="black" className="mb-4">
        Environment History
      </Typography>
      <Card className="w-full shadow-xl">
        {/* <CardBody className="px-2 pb-0 h-full">
          <Chart {...chartConfig} className="h-full"/>
        </CardBody> */}
        <CardBody className="p-6">
          <Tabs value={activeTab} className="mt-8">
            <TabsHeader>
              {["daily", "weekly", "monthly"].map((tab) => (
                <Tab
                  key={tab}
                  value={tab}
                  onClick={() => setActiveTab(tab)}
                  className={activeTab === tab ? "text-blue-500" : "text-gray-900"}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody>
              <TabPanel value={activeTab} className="py-4">
                <div className="grid grid-cols-1 gap-8">
                  {/* ApexCharts - Lux */}
                  <motion.div
                    className="bg-white rounded-lg shadow-md p-4 border-4 border-green-500"
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Typography variant="h6" color="blue-gray" className="mb-2">
                      Lux
                    </Typography>
                    <Typography color="gray" className="mb-4">
                      Latest Reading: {latestLux} lx
                    </Typography>
                    <ReactApexChart
                      options={apexOptions(
                        "Lux",
                        prepareChartData(currentData, "lux"),
                        activeTab
                      )}
                      series={[
                        {
                          name: "Lux",
                          data: prepareChartData(currentData, "lux").data,
                        },
                      ]}
                      type="line"
                      height={300}
                    />
                  </motion.div>

                  {/* ApexCharts - Solar Intensity */}
                  <motion.div
                    className="bg-white rounded-lg shadow-md p-4 border-4 border-green-500"
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Typography variant="h6" color="blue-gray" className="mb-2">
                      Solar Intensity
                    </Typography>
                    <Typography color="gray" className="mb-4">
                      Latest Reading: {latestSolarIntensity} W/m2
                    </Typography>
                    <ReactApexChart
                      options={apexOptions(
                        "Solar Intensity",
                        prepareChartData(currentData, "solarIntensity"),
                        activeTab
                      )}
                      series={[
                        {
                          name: "Solar Intensity",
                          data: prepareChartData(currentData, "solarIntensity").data,
                        },
                      ]}
                      type="line"
                      height={300}
                    />
                  </motion.div>

                  {/* Recharts - Wind Direction */}
                  {/* <motion.div
                    className="bg-white rounded-lg shadow-md p-4 border-4 border-green-100"
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    <Typography variant="h6" color="blue-gray" className="mb-2">
                      Wind Directions
                    </Typography>
                    <Typography color="gray" className="mb-4">
                      Latest Reading: {latestWindDirection}
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart data={rechartsData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Line
                          type="monotone"
                          dataKey="value"
                          stroke="#4ade80"
                          strokeWidth={2}
                          dot={false}
                          animationDuration={2000}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </motion.div> */}

                  {/* Highcharts - Wind Speed */}
                  <motion.div
                    className="bg-white rounded-lg shadow-md p-4 border-4 border-blue-300"
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                  >
                    <Typography variant="h6" color="blue-gray" className="mb-2">
                      Wind Speed
                    </Typography>
                    <Typography color="gray" className="mb-4">
                      Latest Reading: {latestWindSpeed} km/h
                    </Typography>
                    <HighchartsReact highcharts={Highcharts} options={highchartsOptions} />
                  </motion.div>
                </div>
              </TabPanel>
            </TabsBody>
          </Tabs>
        </CardBody>
      </Card>
    </motion.div>
  );
};

export default EnvironmentHistory;