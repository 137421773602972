import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { useSpring, animated } from 'react-spring';
import { FiMenu, FiX, FiSun, FiMoon } from 'react-icons/fi';
import { FaChartLine, FaSignOutAlt, FaWater, FaUser, FaChartBar, FaUsers, FaUserShield, FaCalendarAlt,  FaHistory, FaLeaf, FaRobot, FaProjectDiagram, FaWind, FaCloudSunRain } from 'react-icons/fa';
import Alerts from './Alerts';

const MenuItems = ({ icon, text, isActive, onClick, theme, isOpen, subItems, isSubMenuOpen }) => {
  const spring = useSpring({
    scale: isActive ? 1.05 : 1,
    config: { tension: 300, friction: 10 },
  });

  return (
    <animated.li
      style={spring}
      className={`flex flex-col items-start p-2 space-y-2 rounded-md cursor-pointer transition-all duration-200 ease-in-out mb-4
        ${isActive
          ? `${theme === 'dark' ? 'bg-green-700 text-black' : 'bg-green-200 text-green-900'}`
          : `hover:${theme === 'dark' ? 'bg-green-800' : 'bg-green-100'}`
        }
        ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}
      `}
      onClick={onClick}
    >
      <div className="flex items-center w-full">
        <motion.div
          whileHover={{ rotate: 360 }}
          transition={{ duration: 0.5 }}
        >
          {React.cloneElement(icon, { className: `w-6 h-6 ${isActive ? 'text-green-500' : ''}` })}
        </motion.div>
        {isOpen && <span className="ml-3 font-medium">{text}</span>}
      </div>
      {isOpen && subItems && isSubMenuOpen && (
        <motion.ul
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
          className="ml-6 space-y-2 w-full"
        >
          {subItems.map((subItem, index) => (
            <motion.li
              key={index}
              className="cursor-pointer hover:text-green-500 flex items-center"
              onClick={(e) => {
                e.stopPropagation();
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {React.cloneElement(subItem.icon, { className: "w-4 h-4 mr-2" })}
              <Link to={subItem.link}>{subItem.text}</Link>
            </motion.li>
          ))}
        </motion.ul>
      )}
    </animated.li>
  );
};

const Sidebar = () => {
  const { isAdmin } = useSelector((state) => state.login);
  const [isOpen, setIsOpen] = useState(true);
  const [activeItem, setActiveItem] = useState('Dashboard');
  const [theme, setTheme] = useState('light');
  const [isMobile, setIsMobile] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const speak = () => {
    const utterance = new SpeechSynthesisUtterance("You have 5 Important Alerts");
    speechSynthesis.speak(utterance);
  }

  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
  };

  const tenantMenuItems = [
    {
      icon: <FaChartBar />, text: 'Dashboard', link: '/tenant', subItems: [
        { icon: <FaChartBar />, text: 'Overview', link: '/tenant' },
      ]
    },

    {
      icon: <FaProjectDiagram />, text: 'Process', link: '/tenant', subItems: [
        { icon: <FaHistory />, text: 'Process History', link: '/tenant/processhistory' },
        { icon: <FaCalendarAlt />, text: 'Log Process', link: '/tenant/logprocess' }
      ]
    },

    {
      icon: <FaWater />, text: 'Irrigation', link: '/tenant/irrigation', subItems: [
        { icon: <FaCalendarAlt />, text: 'Schedule', link: '/tenant/irrigation' },
      ]
    },
    {
      icon: <FaChartLine />, text: 'History', link: '/tenant/processhistory', subItems: [
        { icon: <FaLeaf />, text: 'Soil', link: '/tenant/soilhistory' },
        { icon: <FaWind />, text: 'Weather', link: '/tenant/weatherhistory' },
        { icon: <FaCloudSunRain />, text: 'Environment', link: '/tenant/environmenthistory' }
      ]
    },
    { icon: <FaSignOutAlt />, text: 'Logout' },
  ];


  const adminMenuItems = [
    {
      icon: <FaChartBar />, text: 'Dashboard', link: '/admin', subItems: [
        { icon: <FaChartBar />, text: 'Overview', link: '/admin' },
        { icon: <FaChartLine />, text: 'Analytics', link: '/admin/analytics' },
      ]
    },
    {
      icon: <FaUser />, text: 'Admin', link: '/admin', subItems: [
        { icon: <FaUser />, text: 'Manage Users', link: '/admin/users' },
        { icon: <FaRobot />, text: 'KiaanGPT', link: '/admin/kiaangpt' },
      ]
    },
    {
      icon: <FaUsers />, text: 'Tenants', link: '/admin', subItems: [
        { icon: <FaUserShield />, text: 'Manage Tenants', link: '/admin/tenants' },
        { icon: <FaUserShield />, text: 'Onboard Tenant', link: '/admin/onboardtenant' },
        { icon: <FaUserShield />, text: 'Tenant Profile', link: '/admin/onboardtenant/formdata' }

      ]
    },
    {
      icon: <FaProjectDiagram />, text: 'Process', link: '/admin', subItems: [
        { icon: <FaUserShield />, text: 'Process Management', link: '/admin/process' },
        { icon: <FaHistory />, text: 'Process History', link: '/admin/processhistory' }
      ]
    },
    {
      icon: <FaWater />, text: 'Irrigation', link: '/tenant/irrigation', subItems: [
        { icon: <FaCalendarAlt />, text: 'Schedule', link: '/tenant/irrigation' },
      ]
    },
    {
      icon: <FaHistory />, text: 'History', link: '/tenant/processhistory', subItems: [
        { icon: <FaLeaf />, text: 'Soil', link: '/tenant/soilhistory' },
        { icon: <FaWind />, text: 'Weather', link: '/tenant/weatherhistory' },
        { icon: <FaCloudSunRain />, text: 'Environment', link: '/tenant/environmenthistory' }
      ]
    },
  ];

  var menuItems;
  if (isAdmin) {
    menuItems = adminMenuItems;
  }
  else {
    menuItems = tenantMenuItems;
  }

  const sidebarVariants = {
    open: { width: '16rem', transition: { type: 'spring', stiffness: 300, damping: 30 } },
    closed: { width: '4rem', transition: { type: 'spring', stiffness: 300, damping: 30 } },
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width < 768);
      setIsOpen(width >= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleItemClick = (item) => {
    if (isMobile) {
      setActiveSubMenu(prevSubMenu => prevSubMenu === item.text ? null : item.text);
    } else {
      if (activeItem === item.text) {
        setActiveSubMenu(prevSubMenu => prevSubMenu === item.text ? null : item.text);
      } else {
        setActiveItem(item.text);
        setActiveSubMenu(item.text);
      }
    }
  };

  if (isMobile) {
    return (
      <>
        <Alerts />
        <motion.nav
          className={`fixed bottom-0 left-0 right-0 z-40 ${theme === 'dark' ? 'bg-gray-900' : 'bg-white'}`}
        >
          <motion.ul
            className="flex items-center justify-center overflow-x-auto "
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={0.1}
          >
            {menuItems.map((item, index) => (
              <motion.li
                key={item.text}
                className={`flex-shrink-0 p-4 ${activeItem === item.text ? 'text-green-500' : 'text-black'} pl-5 pr-5`}
                onClick={() => handleItemClick(item)}
                whileTap={{ scale: 0.95 }}
              >
                {React.cloneElement(item.icon, { size: 24 })}
              </motion.li>
            ))}
          </motion.ul>
        </motion.nav>
        <AnimatePresence>
          {activeSubMenu && (
            <motion.nav
              initial={{ y: 100 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
              className={`fixed bottom-16 left-0 right-0 z-30 ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-100'}`}
            >
              <ul className="flex items-center justify-center overflow-x-auto p-2">
                {menuItems.find(item => item.text === activeSubMenu)?.subItems.map((subItem, index) => (
                  <motion.li
                    key={subItem.text}
                    className={`flex-shrink-0 p-2 mx-2 rounded-md cursor-pointer ${activeItem === subItem.text ? 'text-green-500' : ''}`}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Link to={subItem.link} className="flex flex-col items-center">
                      {React.cloneElement(subItem.icon, { size: 20 })}
                      <span className="text-xs mt-1">{subItem.text}</span>
                    </Link>
                  </motion.li>
                ))}
              </ul>
            </motion.nav>
          )}
        </AnimatePresence>
      </>
    );
  }

  return (
    <>
      <AnimatePresence>
        <motion.aside
          initial="closed"
          animate={isOpen ? "open" : "closed"}
          variants={sidebarVariants}
          className={`h-full transition-all duration-300 ease-in-out overflow-hidden
            ${theme === 'dark'
              ? 'bg-gradient-to-b from-green-900 via-blue-700 to-gray-900 text-white'
              : 'bg-gradient-to-b from-green-100 via-blue-50 to-white text-gray-900'
            }
          `}
        >

          <div className="flex flex-col h-full">
            <div className="flex items-center justify-between p-4">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setIsOpen(!isOpen)}
                className={`z-50 p-2 rounded-full shadow-lg transition-all duration-300 ease-in-out
                  ${theme === 'dark' ? 'bg-green-800 text-white' : 'bg-white text-gray-800'}
                  ${isOpen ? 'left-64' : 'left-4'}
                `}
              >
                {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
              </motion.button>

              <button onClick={toggleTheme} className="p-2 rounded-full hover:bg-opacity-20 hover:bg-gray-700">
                {theme === 'dark' ? <FiSun size={20} /> : <FiMoon size={20} />}
              </button>
            </div>

            <nav className="flex-grow">
              <ul className="space-y-4 p-4">
                {menuItems.map((item) => (
                  <MenuItems
                    key={item.text}
                    icon={item.icon}
                    text={item.text}
                    isActive={activeItem === item.text}
                    onClick={() => handleItemClick(item)}
                    theme={theme}
                    isOpen={isOpen}
                    subItems={item.subItems}
                    isSubMenuOpen={activeSubMenu === item.text}
                  />
                ))}
              </ul>
            </nav>

            {isOpen && (
              <div className={`p-4 ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-100'}`}>
                <h3 className="text-lg font-semibold mb-2">Crop Health Index</h3>
                <div className="relative pt-1">
                  <div className="flex mb-2 items-center justify-between">
                    <div>
                      <span className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full ${theme === 'dark' ? 'text-green-300 bg-green-900' : 'text-green-600 bg-green-200'}`}>
                        Healthy
                      </span>
                    </div>
                    <div className="text-right">
                      <span className={`text-xs font-semibold inline-block ${theme === 'dark' ? 'text-green-300' : 'text-green-600'}`}>
                        92%
                      </span>
                    </div>
                  </div>
                  <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
                    <div style={{ width: "92%" }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </motion.aside>
      </AnimatePresence>
      <Alerts />
    </>
  );
};

export default Sidebar;