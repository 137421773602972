import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Typography, Progress } from "@material-tailwind/react";
import { CloudArrowUpIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import { POST } from '../../utils/ApiHandler';

const FileUpload = ({ path, onChange, id, onProgress }) => {
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const { token, tenantId } = useSelector((state) => state.login);
    const filePickerRef = useRef();

    const pickFileHandler = () => {
        filePickerRef.current.click();
    };

    const pickHandler = async (event) => {
        if (event.target.files && event.target.files.length !== 0) {
            const files = Array.from(event.target.files);
            const uploadedFiles = [];
            setUploading(true);
            setUploadProgress(0);

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const formData = new FormData();
                formData.append('file', file);

                try {
                    const data = await POST(
                        `${process.env.REACT_APP_API_URL}/api/v1/common/file/upload?tenantId=${tenantId}&path=${path}`,
                        formData,
                        token,
                        (progressEvent) => {
                            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress(progress);
                            onProgress(progress);
                        }
                    );
                    if (data.success) {
                        const { common: sasUrl, fileName, blobName } = data.data;
                        uploadedFiles.push({ sasUrl, fileName, blobName });
                    } else {
                        console.error('Upload failed:', data.message);
                    }
                } catch (error) {
                    console.error('Upload error:', error);
                }

                setUploadProgress((i + 1) / files.length * 100);
                onProgress((i + 1) / files.length * 100);
            }

            setUploading(false);
            onChange(uploadedFiles);
        }
    };

    return (
        <motion.div
            className="w-full"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <input
                id={id}
                type="file"
                className="hidden"
                ref={filePickerRef}
                accept=".jpg,.jpeg,.png,.mp4,.mpeg,.pdf"
                multiple
                onChange={pickHandler}
            />
            <Button
                variant="gradient"
                color="blue"
                className="flex items-center gap-3"
                fullWidth
                onClick={pickFileHandler}
                disabled={uploading}
            >
                <CloudArrowUpIcon strokeWidth={2} className="h-5 w-5" /> Upload Files
            </Button>
            {uploading && (
                <div className="mt-4">
                    <Typography variant="small" color="blue-gray" className="mb-2">
                        Uploading: {Math.round(uploadProgress)}%
                    </Typography>
                    <Progress value={uploadProgress} color="blue" />
                </div>
            )}
        </motion.div>
    );
};

export default FileUpload;