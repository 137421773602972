import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Card,
  CardBody,
  Typography,
  Tabs,
  TabsHeader,
  Tab,
  TabsBody,
  TabPanel,
} from "@material-tailwind/react";
import Chart from 'react-apexcharts';
import { motion } from "framer-motion";
import { GET } from '../utils/ApiHandler';

const SoilHistory = () => {
  const [activeTab, setActiveTab] = useState("daily");
  const [weatherData, setWeatherData] = useState([]);

  useEffect(() => {
    fetchWeatherData();
  }, []);

  const fetchWeatherData = async () => {
    try {
      const response = await GET(`${process.env.REACT_APP_API_URL}/api/v1/iot/iotData`);
      setWeatherData(response.data.iotData);
    } catch (error) {
      console.error("Error fetching weather data:", error);
    }
  };

  const processDataForPeriod = (data, period) => {
    const currentDate = new Date();
    let startDate = new Date(currentDate);
    let filteredData = [];

    switch (period) {
      case 'daily':
        startDate.setHours(0, 0, 0, 0);
        filteredData = data.filter(item => new Date(item.createdAt) >= startDate);
        break;
      case 'weekly':
        startDate.setDate(currentDate.getDate() - 7);
        filteredData = data.filter(item => new Date(item.createdAt) >= startDate);
        break;
      case 'monthly':
        startDate.setMonth(currentDate.getMonth() - 1);
        filteredData = data.filter(item => new Date(item.createdAt) >= startDate);
        break;
      default:
        break;
    }

    return filteredData.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  };

  const prepareChartData = (data, field) => {
    if (data.length === 0) return { data: [], min: 0, max: 40 };

    const values = data.map(item => parseFloat(item[field]));
    const min = Math.min(...values);
    const max = Math.max(...values);

    return {
      data: data.map(item => ({
        x: new Date(item.createdAt).getTime(),
        y: parseFloat(item[field])
      })),
      min: Math.max(0, min - (max - min) * 0.1),
      max: max + (max - min) * 0.1
    };
  };

  const currentData = processDataForPeriod(weatherData, activeTab);

  const latestLeafWetness = currentData.length ? currentData[currentData.length - 1].leafWetness : null;
  const latestSoilTemp = currentData.length ? currentData[currentData.length - 1].soilTemprature : null;
  const latestSoilMoistureL1 = currentData.length ? currentData[currentData.length - 1].soilMoistureL1 : null;
  const latestSoilMoistureL2 = currentData.length ? currentData[currentData.length - 1].soilMoistureL2 : null;

  const options = (title, data, period) => {
    const currentDate = new Date();
    let startDate = new Date(currentDate);
    let endDate;

    switch (period) {
      case 'daily':
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(currentDate);
        endDate.setHours(24, 0, 0, 0);
        break;
      case 'weekly':
        startDate.setDate(currentDate.getDate() - 7);
        break;
      case 'monthly':
        startDate.setMonth(currentDate.getMonth() - 1);
        break;
      default:
        break;
    }

    return {
      series: [{
        name: title,
        data: data.data
      }],
      chart: {
        height: 300,
        type: 'line',
        zoom: { enabled: false }
      },
      dataLabels: { enabled: false },
      stroke: { curve: 'straight' },
      title: { text: title, align: 'left' },
      grid: { row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 } },
      xaxis: {
        type: 'datetime',
        min: startDate.getTime(),
        max: period === 'daily' ? endDate.getTime() : currentDate.getTime(),
        tickAmount: period === 'daily' ? 24 : undefined,
        labels: {
          formatter: function (val, timestamp) {
            return period === 'daily'
              ? new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
              : new Date(timestamp).toLocaleDateString();
          }
        }
      },
      yaxis: {
        title: { text: title },
        min: data.min,
        max: data.max
      }
    };
  };

  const chartConfig = {
    type: "line",
    height: 240,
    series: [
      {
        name: "Leaf Wetness",
        data: prepareChartData(currentData, 'leafWetness').data.map(item => item.y),
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      title: {
        show: "",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#062E88"],
      stroke: {
        lineCap: "round",
        curve: "smooth",
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: 'datetime',
        categories: prepareChartData(currentData, 'leafWetness').data.map(item => item.x),
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
        },
      },
      grid: {
        show: true,
        borderColor: "#dddddd",
        strokeDashArray: 5,
        xaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 5,
          right: 20,
        },
      },
      fill: {
        opacity: 0.8,
      },
      tooltip: {
        theme: "dark",
      },
    },
  };

  // Recharts data preparation
  const rechartsData = currentData.map((item) => ({
    name: new Date(item.createdAt).toLocaleString(),
    value: item.soilMoistureL1,
  }));

  // Highcharts options
  const highchartsOptions = {
    chart: {
      type: "line",
    },
    title: {
      text: "Soil Moisture L2",
    },
    xAxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: "Soil Moisture L2",
      },
    },
    series: [
      {
        name: "Soil Moisture L2",
        data: currentData.map((item) => [
          new Date(item.createdAt).getTime(),
          item.soilMoistureL2,
        ]),
      },
    ],
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h2" color="black" className="mb-4">
        Soil History
      </Typography>
      <Card className="w-full shadow-xl">
        {/* <CardBody className="px-2 pb-0 h-full">
          <Chart {...chartConfig} className="h-full"/>
        </CardBody> */}
        <CardBody className="p-6">
          <Tabs value={activeTab} className="mt-8">
            <TabsHeader>
              {["daily", "weekly", "monthly"].map((tab) => (
                <Tab
                  key={tab}
                  value={tab}
                  onClick={() => setActiveTab(tab)}
                  className={activeTab === tab ? "text-blue-500" : "text-gray-900"}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody>
              <TabPanel value={activeTab} className="py-4">
                <div className="grid grid-cols-1 gap-8">
                  {/* Leaf Wetness Chart */}
                  <motion.div
                    className="bg-white rounded-lg shadow-md p-4 border-4 border-blue-800"
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Typography variant="h6" color="blue-gray" className="mb-2">
                      Leaf Wetness
                    </Typography>
                    <Typography color="gray" className="mb-4">
                      Latest Reading: {latestLeafWetness} %
                    </Typography>
                    <Chart {...chartConfig} className="h-full" />
                  </motion.div>

                  {/* Soil Temperature Chart */}
                  <motion.div
                    className="bg-white rounded-lg shadow-md p-4 border-4 border-light-blue-500"
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Typography variant="h6" color="blue-gray" className="mb-2">
                      Soil Temperature
                    </Typography>
                    <Typography color="gray" className="mb-4">
                      Latest Reading: {latestSoilTemp} °C
                    </Typography>
                    <ReactApexChart
                      options={options(
                        "Soil Temperature",
                        prepareChartData(currentData, "soilTemprature"),
                        activeTab
                      )}
                      series={[
                        {
                          name: "Soil Temperature",
                          data: prepareChartData(currentData, "soilTemprature").data,
                        },
                      ]}
                      type="line"
                      height={300}
                    />
                  </motion.div>

                  {/* Soil Moisture L1 Chart */}
                  <motion.div
                    className="bg-white rounded-lg shadow-md p-4 border-4 border-green-500"
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    <Typography variant="h6" color="blue-gray" className="mb-2">
                      Soil Moisture L1
                    </Typography>
                    <Typography color="gray" className="mb-4">
                      Latest Reading: {latestSoilMoistureL1} cbar
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart data={rechartsData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Line
                          type="monotone"
                          dataKey="value"
                          stroke="#4ade80"
                          strokeWidth={2}
                          dot={false}
                          animationDuration={2000}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </motion.div>

                  {/* Soil Moisture L2 Chart */}
                  <motion.div
                    className="bg-white rounded-lg shadow-md p-4 border-4 border-blue-300"
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                  >
                    <Typography variant="h6" color="blue-gray" className="mb-2">
                      Soil Moisture L2
                    </Typography>
                    <Typography color="gray" className="mb-4">
                      Latest Reading: {latestSoilMoistureL2} cbar
                    </Typography>
                    <HighchartsReact highcharts={Highcharts} options={highchartsOptions} />
                  </motion.div>
                </div>
              </TabPanel>
            </TabsBody>
          </Tabs>
        </CardBody>
      </Card>
    </motion.div>
  );
};

export default SoilHistory;