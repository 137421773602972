import React from 'react';
import { Typography, Input, IconButton } from "@material-tailwind/react";
import { BellIcon, Cog6ToothIcon, UserCircleIcon } from "@heroicons/react/24/solid";

const Nav = () => {
  return (
    <header className="bg-white shadow rounded">
      <div className="container mx-auto px-4 py-4 flex items-center justify-between">
        <div>
          <Typography variant="small" color="blue-gray" className="font-normal">
            Admin / Dashboard
          </Typography>
          <Typography variant="h5" color="blue-gray">
            Analytics
          </Typography>
        </div>
        <div className="flex items-center space-x-4">
          <div className="w-72 hidden md:block">
            <Input type="search" label="Search" />
          </div>
          <IconButton variant="text" color="blue-gray">
            <UserCircleIcon className="h-5 w-5" />
          </IconButton>
          <IconButton variant="text" color="blue-gray">
            <BellIcon className="h-5 w-5" />
          </IconButton>
          <IconButton variant="text" color="blue-gray">
            <Cog6ToothIcon className="h-5 w-5" />
          </IconButton>
        </div>
      </div>
    </header>
  );
};

export default Nav;