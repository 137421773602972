import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Input,
  Checkbox,
  Button,
  Typography,
  Alert,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { FaGoogle, FaTwitter, FaEye, FaEyeSlash, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { POST, GET } from '../utils/ApiHandler';
import { loginActions } from '../redux/login-slice';
import bgImage from '../Assets/Services/s3.jpg';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [tenants, setTenants] = useState([]);
  const [currentTenantIndex, setCurrentTenantIndex] = useState(0);

  useEffect(() => {
    fetchTenants();
  }, []);

  const fetchTenants = async () => {
    try {
      const data = await GET(`${process.env.REACT_APP_API_URL}/api/v1/tenants/fulllist`);
      if (data?.success) {
        setTenants(data.data.tenants);
      }
    } catch (error) {
      console.error("Error fetching tenants:", error);
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const showAlert = (message) => {
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;
    await login(enteredEmail, enteredPassword);
  };

  const login = async (email, password) => {
    let body = { email, password };
    let data;
    try {
      data = await POST(`${process.env.REACT_APP_API_URL}/api/v1/auth/login`, body);

      if (data?.success) {
        dispatch(loginActions.userLoggedInStatus({ status: true }));
        dispatch(loginActions.userLogin({ token: data.data.user.token }));
        dispatch(loginActions.setTenantId({ tenantId: tenantId }))
        if (data?.data.user.role === 'Admin') {
          dispatch(loginActions.setIsAdmin({ isAdmin: true }));
          navigate("/admin");
        } else {
          navigate("/tenant");
        }
      } else {
        showAlert("Invalid Credentials");
      }
    } catch (error) {
      showAlert("Login failed. Please try again.");
    }
  };

  const handleProjectSelect = (selectedTenantId) => {
    setTenantId(selectedTenantId);
    dispatch(loginActions.setTenantId({ tenantId: selectedTenantId }));
    handleCloseModal();
  };

  const nextTenant = () => {
    setCurrentTenantIndex((prevIndex) =>
      prevIndex === tenants.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevTenant = () => {
    setCurrentTenantIndex((prevIndex) =>
      prevIndex === 0 ? tenants.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-green-400 to-blue-500 p-4 sm:p-6 lg:p-8 login-container">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-5xl rounded-2xl shadow-2xl overflow-hidden"
      >
        <div className="flex flex-col lg:flex-row border-2 border-black">
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="lg:w-1/2 relative"
          >
            <img
              src={bgImage}
              alt="Login Background"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
              <Typography variant="h1" color="white" className="text-center font-bold text-4xl lg:text-6xl">
                KIAANIOT
              </Typography>
            </div>
          </motion.div>

          <motion.div
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="lg:w-1/2 p-8 lg:p-12 bg-black"
          >
            <Typography variant="h3" color="white" className="text-center mb-6 font-bold">
              Sign In
            </Typography>
            <form onSubmit={formSubmitHandler} className="space-y-6">
              {/* Email Input */}
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <Typography variant="h6" color="white" className="mb-2 font-semibold">
                  Email
                </Typography>
                <Input
                  type="email"
                  placeholder="tenant@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  inputRef={emailInputRef}
                  className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-blue-500 focus:!border-t-blue-500 focus:ring-blue-500/20"
                  labelProps={{
                    className: "hidden",
                  }}
                  required
                  containerProps={{ className: "min-w-[100px]" }}
                />
              </motion.div>
              {/* Password Input */}
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="relative"
              >
                <Typography variant="h6" color="white" className="mb-2 font-semibold">
                  Password
                </Typography>
                <Input
                  type={showPassword ? "text" : "password"}
                  placeholder="••••••••"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  inputRef={passwordInputRef}
                  className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-blue-500 focus:!border-t-blue-500 focus:ring-blue-500/20 pr-10"
                  labelProps={{
                    className: "hidden",
                  }}
                  required
                  containerProps={{ className: "min-w-[100px]" }}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute right-3 top-[38px] text-gray-500 focus:outline-none"
                >
                  {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                </button>
              </motion.div>
              {/* Remember Me and Forgot Password */}
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="flex items-center justify-between"
              >
                <Checkbox
                  color="blue"
                  label={
                    <Typography variant="small" color="white" className="flex items-center font-normal">
                      Remember me
                    </Typography>
                  }
                  containerProps={{ className: "-ml-2.5" }}
                />
                <Typography
                  as="a"
                  href="#"
                  variant="small"
                  color="blue"
                  className="font-medium transition-colors hover:text-blue-700"
                >
                  Forgot password?
                </Typography>
              </motion.div>
              {/* Select Project Button */}
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                <Button
                  className="mt-6 bg-blue-500 hover:bg-blue-600 transition-colors duration-300"
                  fullWidth
                  onClick={handleOpenModal}
                >
                  {tenantId ? tenantId : "Select Project"}
                </Button>
              </motion.div>
              {/* Sign In Button */}
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.7 }}
              >
                <Button
                  type="submit"
                  className="mt-6 bg-black hover:bg-blue-600 transition-colors duration-300 border-2 border-white"
                  fullWidth
                >
                  SIGN IN
                </Button>
              </motion.div>
            </form>
            {/* Social Login Options */}
            <div className="relative flex items-center justify-center my-4">
              <hr className="w-full border-gray-300" />
              <span className="absolute bg-black px-4 text-white text-sm">Or continue with</span>
            </div>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.8 }}
              className="grid grid-cols-2 gap-4"
            >
              <Button
                size="lg"
                variant="outlined"
                color="white"
                className="flex items-center gap-3 justify-center shadow-md hover:shadow-lg transition-shadow duration-300 bg-white text-black"
              >
                <FaGoogle className="h-5 w-5" />
                Google
              </Button>
              <Button
                size="lg"
                variant="outlined"
                color="white"
                className="flex items-center gap-3 justify-center shadow-md hover:shadow-lg transition-shadow duration-300 bg-black"
              >
                <FaTwitter className="h-5 w-5" />
                Twitter
              </Button>
            </motion.div>
            {/* Sign Up Link */}
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.9 }}
            >
              <Typography as="a" href="/#pricing" color="white" className="text-center font-normal mt-6">
                Not a Tenant? {" "}
                <span className="font-medium text-blue-400 transition-colors hover:text-blue-700">
                  Subscribe now
                </span>
              </Typography>
            </motion.div>
          </motion.div>
        </div>
      </motion.div>

      {/* Project Selection Modal */}
      <Dialog
        open={openModal}
        handler={handleCloseModal}
        size="sm"
        className="bg-white shadow-none sm:my-8 sm:w-full sm:max-w-lg"
      >
        <DialogHeader>Select a Project</DialogHeader>
        <DialogBody divider className="h-[300px] overflow-hidden">
          <div className="relative w-full h-full">
            <img
              src={bgImage}
              alt="Project Background"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
              {tenants.length > 0 ? (
                <Typography variant="h4" color="white" className="text-center font-bold">
                  {tenants[currentTenantIndex]?.project_code}
                </Typography>
              ) : (
                <Typography variant="h4" color="white" className="text-center font-bold">
                  No tenants available
                </Typography>
              )}
            </div>
            {tenants.length > 0 && (
              <>
                <Button
                  color="white"
                  variant="text"
                  size="sm"
                  className="!absolute top-1/2 left-4 -translate-y-1/2"
                  onClick={prevTenant}
                >
                  <FaChevronLeft size={20} />
                </Button>
                <Button
                  color="white"
                  variant="text"
                  size="sm"
                  className="!absolute top-1/2 right-4 -translate-y-1/2"
                  onClick={nextTenant}
                >
                  <FaChevronRight size={20} />
                </Button>
              </>
            )}
          </div>
        </DialogBody>
        <DialogFooter>
          {tenants.length > 0 ? (
            <Button
              variant="gradient"
              color="green"
              onClick={() => handleProjectSelect(tenants[currentTenantIndex]?.project_code)}
            >
              Select
            </Button>
          ) : (
            <Button variant="text" color="red" onClick={handleCloseModal}>
              Close
            </Button>
          )}
        </DialogFooter>
      </Dialog>

      {/* Alert */}
      <AnimatePresence>
        {alertOpen && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className="fixed top-4 right-4 z-50"
          >
            <Alert
              open={alertOpen}
              onClose={() => setAlertOpen(false)}
              animate={{
                mount: { y: 0 },
                unmount: { y: 100 },
              }}
              color="red"
            >
              {alertMessage}
            </Alert>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Login;