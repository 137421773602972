import React, { useState, useEffect } from 'react';
import { Card, CardBody, Typography } from "@material-tailwind/react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const DailySalesChart = () => {
  const [data, setData] = useState([50, 40, 300, 320, 500, 350, 200, 230, 500]);

  const options = {
    chart: {
      type: 'spline',
      height: 300
    },
    title: {
      text: null
    },
    xAxis: {
      categories: ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      labels: {
        style: {
          color: '#64748B'
        }
      }
    },
    yAxis: {
      title: {
        text: null
      },
      labels: {
        style: {
          color: '#64748B'
        }
      }
    },
    series: [{
      name: 'Sales',
      data: data,
      color: '#3B82F6'
    }],
    credits: {
      enabled: false
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setData(prevData =>
        prevData.map(value => Math.max(0, value + Math.floor(Math.random() * 100) - 50))
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Card className="overflow-hidden">
      <CardBody>
        <Typography variant="h6" color="blue-gray" className="mb-2">
          Daily Sales - Kiaaniot Smart Pole
        </Typography>
        <Typography variant="small" color="gray" className="mb-4">
          15% increase in today sales
        </Typography>
        <HighchartsReact highcharts={Highcharts} options={options} />
        <Typography variant="small" color="gray" className="mt-4">
          updated 4 min ago
        </Typography>
      </CardBody>
    </Card>
  );
};

export default DailySalesChart;