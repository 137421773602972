import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    Button,
    Typography,
    Alert,
} from "@material-tailwind/react";
import {
    Cog6ToothIcon,
    BuildingOffice2Icon,
    CalendarIcon,
    MapPinIcon,
    CubeIcon,
} from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import { GET, PUT, POST } from '../../utils/ApiHandler';
import { loginActions } from '../../redux/login-slice';

const TenantForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { tenantsId } = useParams();
    const { token } = useSelector((state) => state.login);

    const [formData, setFormData] = useState({
        project_name: '',
        project_code: '',
        project_start_date: '',
        location: '',
        plantation_name: '',
        cluster: ''
    });
    const [alert, setAlert] = useState({ show: false, message: '', color: 'green' });

    useEffect(() => {
        if (tenantsId) {
            const getTenants = async () => {
                let { data } = await GET(`${process.env.REACT_APP_API_URL}/api/v1/tenants/${tenantsId}`, token);
                let { project_name, project_code, project_start_date, location, plantation_name, cluster } = data.tenants;
                setFormData({ project_name, project_code, project_start_date, location, plantation_name, cluster });
            };
            getTenants();
        }
    }, [token, tenantsId]);

    const tenantsSubmitHandler = async (e) => {
        e.preventDefault();
        let data;
        if (tenantsId) {
            data = await PUT(`${process.env.REACT_APP_API_URL}/api/v1/tenants/${tenantsId}`, formData, token);
        } else {
            data = await POST(`${process.env.REACT_APP_API_URL}/api/v1/tenants/create`, formData, token);
            dispatch(loginActions.projectStartDate({ projectStartDate: formData.project_start_date }));
        }
        if (data.success) {
            setAlert({
                show: true,
                message: `Successfully ${tenantsId ? 'updated' : 'added'} Tenant!`,
                color: 'green'
            });
            setTimeout(() => navigate('/admin/tenants'), 2000);
        } else {
            setAlert({
                show: true,
                message: `Error ${tenantsId ? 'updating' : 'adding'} Tenant: ${data.message}`,
                color: 'red'
            });
        }
    };

    const cancelHandler = () => {
        navigate('/admin/tenants');
    };

    const textInputHandler = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
        if (id === "project_start_date") {
            setFormData({ ...formData, [id]: new Date(value).toISOString().split('T')[0] });
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="min-h-screen p-8 bg-gradient-to-br from-green-400 to-blue-500 flex items-center justify-center rounded-xl"
        >
            <Card className="w-full max-w-[40rem] bg-white bg-opacity-90 backdrop-blur-md">
                <CardHeader
                    color="blue"
                    floated={false}
                    shadow={false}
                    className="m-0 grid place-items-center rounded-b-none py-8 px-4 text-center"
                >
                    <div className="mb-4 rounded-full border border-white/10 bg-white/10 p-6 text-white">
                        <Cog6ToothIcon className="h-10 w-10" />
                    </div>
                    <Typography variant="h4" color="white">
                        {tenantsId ? 'Edit' : 'Initialize'} Tenant
                    </Typography>
                </CardHeader>
                <CardBody className="flex flex-col gap-4">
                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.1 }}
                    >
                        <Input
                            label="Project Name"
                            size="lg"
                            icon={<BuildingOffice2Icon className="h-5 w-5" />}
                            value={formData.project_name}
                            onChange={textInputHandler}
                            id="project_name"
                        />
                    </motion.div>
                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.2 }}
                    >
                        <Input
                            label="Project Code"
                            size="lg"
                            icon={<CubeIcon className="h-5 w-5" />}
                            value={formData.project_code}
                            onChange={textInputHandler}
                            id="project_code"
                        />
                    </motion.div>
                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.3 }}
                    >
                        <Input
                            type="date"
                            label="Project Start Date"
                            size="lg"
                            icon={<CalendarIcon className="h-5 w-5" />}
                            value={formData.project_start_date}
                            onChange={textInputHandler}
                            id="project_start_date"
                        />
                    </motion.div>
                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.4 }}
                    >
                        <Input
                            label="Location"
                            size="lg"
                            icon={<MapPinIcon className="h-5 w-5" />}
                            value={formData.location}
                            onChange={textInputHandler}
                            id="location"
                        />
                    </motion.div>
                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.5 }}
                    >
                        <Input
                            label="Plantation Name"
                            size="lg"
                            icon={<CubeIcon className="h-5 w-5" />}
                            value={formData.plantation_name}
                            onChange={textInputHandler}
                            id="plantation_name"
                        />
                    </motion.div>
                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.6 }}
                    >
                        <Input
                            label="Cluster"
                            size="lg"
                            icon={<CubeIcon className="h-5 w-5" />}
                            value={formData.cluster}
                            onChange={textInputHandler}
                            id="cluster"
                        />
                    </motion.div>
                </CardBody>
                <CardFooter className="pt-0">
                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                    >
                        <Button
                            variant="gradient"
                            color="green"
                            fullWidth
                            onClick={tenantsSubmitHandler}
                        >
                            {tenantsId ? 'Update' : 'Save'}
                        </Button>
                    </motion.div>
                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        className="mt-4"
                    >
                        <Button
                            variant="outlined"
                            color="red"
                            fullWidth
                            onClick={cancelHandler}
                        >
                            Cancel
                        </Button>
                    </motion.div>
                </CardFooter>
            </Card>
            {alert.show && (
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    className="fixed top-4 right-4 z-50"
                >
                    <Alert
                        color={alert.color}
                        dismissible={{
                            onClose: () => setAlert({ ...alert, show: false }),
                        }}
                    >
                        {alert.message}
                    </Alert>
                </motion.div>
            )}
        </motion.div>
    );
};

export default TenantForm;