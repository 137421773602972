import { createSlice } from '@reduxjs/toolkit';

const loginSlice = createSlice({
    name: 'login',
    initialState: {
        token: localStorage.getItem('token'),
        isLoggedIn: localStorage.getItem('token') ? true : false,
        projectStartDate: localStorage.getItem('projectStartDate'),
        tenantId: localStorage.getItem('tenantId'),
        isAdmin: false
    },
    reducers: {
        userLogin(state, action) {
            state.token = action.payload.token;
            localStorage.setItem('token', action.payload.token);
        },
        userLoggedInStatus(state, action) {
            state.isLoggedIn = action.payload.status;
        },
        userLogout(state, action) {
            state.token = '';
            localStorage.removeItem('token');
        },
        projectStartDate(state, action) {
            state.projectStartDate = action.payload.projectStartDate;
            localStorage.setItem('projectStartDate', action.payload.projectStartDate);
        },
        setTenantId(state, action) {
            state.tenantId = action.payload.tenantId;
            localStorage.setItem('tenantId', action.payload.tenantId);
        },
        unsetTenantId(state, action) {
            state.tenantId = '';
            localStorage.removeItem('tenantId');
        },
        setIsAdmin(state, action) {
            state.isAdmin = action.payload.isAdmin;
            localStorage.setItem('isAdmin', action.payload.isAdmin);
        },
        unsetIsAdmin(state, action) {
            state.isAdmin = false;
            localStorage.removeItem('isAdmin');
        }
    },
});

export const loginActions = loginSlice.actions;

export default loginSlice;
