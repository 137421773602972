import React, { useState, useEffect } from 'react';
import { Card, CardBody, Typography, Select, Option, Button } from "@material-tailwind/react";
import { motion } from "framer-motion";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default function FertigationHistory() {
  const [selectedZone, setSelectedZone] = useState("All Zones");
  const [selectedView, setSelectedView] = useState("daily");
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'line',
      backgroundColor: 'black'
    },
    title: {
      text: 'Fertigation Data',
      style: { color: '#ffffff' }
    },
    xAxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      labels: { style: { color: '#ffffff' } }
    },
    yAxis: {
      title: {
        text: 'Fertigation Volume (L)',
        style: { color: '#ffffff' }
      },
      labels: { style: { color: '#ffffff' } }
    },
    series: [{
      name: 'Fertigation Volume',
      data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 90, 60, 40]
    }],
    plotOptions: {
      series: {
        animation: {
          duration: 2000
        }
      }
    }
  });

  useEffect(() => {
    // Simulate data update based on selected zone and view
    const newData = Array(12).fill(0).map(() => Math.floor(Math.random() * 100) + 20);
    setChartOptions(prevState => ({
      ...prevState,
      series: [{ ...prevState.series[0], data: newData }]
    }));
  }, [selectedZone, selectedView]);

  return (
    <Card className="w-full overflow-hidden bg-gradient-to-br from-green-400 to-blue-500">
      <CardBody>
        <Typography variant="h4" color="white" className="mb-4 font-semibold">
          Historical Fertigation Data
        </Typography>
        <div className="flex flex-wrap mb-4">
          <div className="w-full md:w-1/2">
            <Select color="blue" label="Select Zone" value={selectedZone} onChange={(value) => setSelectedZone(value)}>
              <Option value="All Zones">All Zones</Option>
              <Option value="Zone 1">Zone 1</Option>
              <Option value="Zone 2">Zone 2</Option>
              <Option value="Zone 3">Zone 3</Option>
              <Option value="Zone 4">Zone 4</Option>
            </Select>
          </div>
          <div className="w-full md:w-1/2 flex rounded overflow-hidden mt-1">
            {['daily', 'weekly', 'monthly'].map((view) => (
              <Button
                key={view}
                color={selectedView === view ? "blue" : "gray"}
                onClick={() => setSelectedView(view)}
                className="capitalize w-full"
              >
                {view}
              </Button>
            ))}
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </motion.div>
      </CardBody>
    </Card>
  );
};