import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Nav from './Nav';
import StatCard from './StatCard';
import PlatformViewsChart from './PlatformViewsChart';
import DailySalesChart from './DailySalesChart';
import CompletedTasksChart from './CompletedTasksChart';
import ProjectOrders from './ProjectOrders';
import { useSelector } from 'react-redux';
import { GET } from '../../utils/ApiHandler';
import TenantCard from './TenantCard';

const Analytics = () => {
  const { token } = useSelector((state) => state.login);
  const [tenants, setTenants] = useState(0);
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    const getNumberOfTenants = async () => {
      try {
        const data = await GET(`${process.env.REACT_APP_API_URL}/api/v1/tenants/fulllist`, token);
        if (data && data.data) {
          setTenants(data.data.tenants.length);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        console.error("Error fetching data:", e);
        setIsLoading(false);
      }
    };
    getNumberOfTenants();
  }, [token]);
  return (
    <motion.section
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className='grid grid-cols-1 lg:grid-cols-1 gap-4 mb-4'>
        <Nav />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">

        <TenantCard
          icon="money"
          title="Tenants"
          value={tenants}
          change="+55% than last week"
          changeColor="text-green-500"
        />
        <StatCard
          icon="users"
          title="Today's Tenants"
          value="2,300"
          change="+66% than last month"
          changeColor="text-green-500"
        />
        <StatCard
          icon="user-plus"
          title="New Tenants"
          value="3,462"
          change="+29% than yesterday"
          changeColor="text-green-500"
        />
        <StatCard
          icon="chart-bar"
          title="Sales"
          value="₹2,00,000"
          change="+5% than yesterday"
          changeColor="text-green-500"
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-8">
        <PlatformViewsChart />
        <DailySalesChart />
        <CompletedTasksChart />

      </div>
      <div className="grid grid-cols-1  gap-4">
        <ProjectOrders />
      </div>
    </motion.section>
  );
};

export default Analytics;