import React from 'react';
import { Typography } from "@material-tailwind/react";
import DataTable from '../DataTable/DataTable';
import { GET, DELETE } from '../../utils/ApiHandler';
import { useSelector } from 'react-redux';

const AdminList = () => {
  const TABLE_HEAD = ["Name", "Email", "Role", "Actions"];
  const { token } = useSelector((state) => state.login);

  const fetchUsers = async () => {
    const data = await GET(`${process.env.REACT_APP_API_URL}/api/v1/users/list`, token);
    return data?.data?.users || [];
  };

  const deleteUser = async (userId) => {
    return await DELETE(`${process.env.REACT_APP_API_URL}/api/v1/users/delete`, { userId: userId }, token);
  };

  const renderUserRow = (user) => (
    <>
      <td className="p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          {user.first_name} {user.last_name}
        </Typography>
      </td>
      <td className="p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          {user.email}
        </Typography>
      </td>
      <td className="p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          {user.role}
        </Typography>
      </td>
    </>
  );

  return (
    <DataTable
      title="Admins"
      subtitle="Manage your organization's administrators"
      addButtonText="Add Admin"
      addButtonLink="/admin/users/add"
      tableHead={TABLE_HEAD}
      fetchData={fetchUsers}
      deleteItem={deleteUser}
      renderTableRow={renderUserRow}
      searchFields={['first_name', 'last_name', 'email']}
    />
  );
};

export default AdminList;