import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Card,
  CardBody,
  IconButton,
} from "@material-tailwind/react";
import { motion, useAnimation } from "framer-motion";
import { ArrowRightIcon, CloudIcon } from "@heroicons/react/24/outline";
import { FaLeaf, FaMicrochip, FaAppStore, FaGooglePlay } from 'react-icons/fa';
import app from '../Assets/LandingPage/app.mp4';

const Hero = () => {
  const controls = useAnimation();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    controls.start({ opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } });
  }, [controls]);

  const phoneVariants = {
    initial: { rotate: -5, y: 20 },
    animate: { rotate: 5, y: -20, transition: { yoyo: Infinity, duration: 3, ease: "easeInOut" } },
  };

  return (
    <div className="relative min-h-screen overflow-hidden">
      {/* Animated background elements */}
      <motion.div
        className="absolute top-0 left-0 w-full h-full opacity-10"
        initial={{ backgroundPosition: '0% 0%' }}
        animate={{ backgroundPosition: '100% 100%' }}
        transition={{ duration: 20, repeat: Infinity, repeatType: 'reverse' }}
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        }}
      />

      <div className="relative z-10 container mx-auto px-4 py-16 flex flex-col lg:flex-row items-center justify-between">
        <motion.div
          className="lg:w-1/2 text-left mb-12 lg:mb-0"
          initial={{ opacity: 0, y: 50 }}
          animate={controls}
        >
          <Typography
            variant="h1"
            color="white"
            className="mb-4 text-5xl lg:text-7xl font-bold leading-tight"
          >
            {/* <span className="bg-gradient-to-r from-green-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text"> Kiaaniot</span> */}
            <span className="text-black"> Kiaaniot</span>
          </Typography>
          <Typography
            variant="lead"
            color="white"
            className="mb-8 text-xl lg:text-2xl font-light"
          >
            Start your Agriculture revolution with our AI-IoT platform inspired by nature and powered by technology.
          </Typography>
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <Button
              size="lg"
              color="black"
              className="rounded-full text-lg px-8 py-3 flex items-center justify-center transition-all duration-300 transform hover:scale-105 hover:shadow-lg"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              
            >
              <a href="/#pricing">
              Get Started
              </a>
              <ArrowRightIcon
                className={`h-5 w-5 ml-2 transition-all duration-300 ${isHovered ? 'translate-x-2' : ''}`}
              />
            </Button>
            <Button
              size="lg"
              variant="outlined"
              color="white"
              className="rounded-full text-lg px-8 py-3 transition-all duration-300 transform hover:scale-105 hover:shadow-lg"
            >
              <a href="/#services">
              Learn More
              </a>
            </Button>
          </div>
        </motion.div>

        <motion.div
          className="lg:w-1/2 relative"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          <motion.div
            className="relative w-[300px] h-[600px] mx-auto"
            variants={phoneVariants}
            initial="initial"
            animate="animate"
          >
            {/* <Card className="w-full h-full overflow-hidden shadow-xl">
              <CardBody className="p-0">
                <img 
                  src={app} 
                  alt="AgriTech App Interface" 
                  className="w-full h-full object-cover"
                />
              </CardBody>
            </Card> */}
            <Card className="w-full h-full overflow-hidden shadow-xl">
              <CardBody className="p-0">
                <video autoPlay loop muted
                  alt="AgriTech App Interface"
                  className="w-full h-full object-cover">
                  <source src={app}></source>
                </video>
              </CardBody>
            </Card>

            {/* Floating feature cards */}
            <Card className="absolute -top-10 -left-20 w-40 shadow-lg">
              <CardBody>
                <CloudIcon className="h-8 w-8 text-blue-500 mb-2" />
                <Typography variant="h6" color="blue-gray">Weather AI</Typography>
                <Typography variant="small" color="gray">Predict and plan</Typography>
              </CardBody>
            </Card>

            <Card className="absolute -bottom-10 -right-20 w-40 shadow-lg">
              <CardBody>
                <FaLeaf className="h-8 w-8 text-green-500 mb-2" />
                <Typography variant="h6" color="blue-gray">Crop Health</Typography>
                <Typography variant="small" color="gray">Real-time monitoring</Typography>
              </CardBody>
            </Card>

            <Card className="absolute top-1/2 -right-24 w-40 shadow-lg">
              <CardBody>
                <FaMicrochip className="h-8 w-8 text-purple-500 mb-2" />
                <Typography variant="h6" color="blue-gray">IoT Sensors</Typography>
                <Typography variant="small" color="gray">Smart farm management</Typography>
              </CardBody>
            </Card>
          </motion.div>
        </motion.div>
      </div>

      {/* Floating action buttons */}
      <div className="absolute bottom-10 right-10 flex space-x-4">
        <IconButton color="white" className="rounded-full shadow-lg">
          <FaAppStore className="w-6 h-6" />
        </IconButton>
        <IconButton color="white" className="rounded-full shadow-lg">
          <FaGooglePlay className="w-6 h-6" />
        </IconButton>
      </div>
    </div>
  );
};

export default Hero;