import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Chip,
} from "@material-tailwind/react";
import { motion } from "framer-motion";

const Irrigation = () => {
  const irrigationData = [
    { zones: "Zone 1 - Zone 2", status: "In Progress", volumeIrrigated: "50000 L", lastIrrigation: "23-07-2024" },
    { zones: "Zone 3 - Zone 4", status: "Completed", volumeIrrigated: "30000 L", lastIrrigation: "24-07-2024" },
    { zones: "Zone 5 - Zone 6", status: "Completed", volumeIrrigated: "0 L", lastIrrigation: "25-07-2024" },
  ];

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const rowVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="w-full max-w-4xl mx-auto"
    >
      <Card className="w-full overflow-hidden bg-gradient-to-br from-green-400 to-blue-500 shadow-xl">
        <CardHeader floated={false} shadow={false} color="transparent" className="rounded-none">
          <div className="flex items-center justify-between">
            <Typography variant="h5" color="white" className="font-bold">
              IRRIGATION
            </Typography>
            <Chip
              value="Week 42"
              className="bg-green-500 text-white font-medium rounded-full"
            />
          </div>
        </CardHeader>
        <CardBody className="px-0 pt-0">
          <div className="overflow-x-auto">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {["Zones", "Status", "Volume Irrigated", "Last Irrigation"].map((head) => (
                    <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50/50 p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {irrigationData.map((row, index) => (
                  <motion.tr
                    key={index}
                    variants={rowVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ delay: index * 0.1 }}
                    className="even:bg-blue-gray-50/50"
                  >
                    <td className="p-4">
                      <Typography variant="small" color="blue-gray" className="font-normal">
                        {row.zones}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Chip
                        value={row.status}
                        color={row.status === "Completed" ? "green" : "blue"}
                        className="font-medium"
                      />
                    </td>
                    <td className="p-4">
                      <Typography variant="small" color="blue-gray" className="font-normal">
                        {row.volumeIrrigated}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography variant="small" color="blue-gray" className="font-normal">
                        {row.lastIrrigation}
                      </Typography>
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
        <div className="bg-blue-gray-50/50 p-4 rounded-b-lg">
          <Typography color="blue-gray" className="font-medium">
            Last Irrigation Volume: 150000 L
          </Typography>
          <Typography color="blue-gray" className="font-medium">
            Total Land: 20 acres (13.32 acres irrigated, 6.68 acres irrigation in progress)
          </Typography>
        </div>
      </Card>
    </motion.div>
  );
};

export default Irrigation;