import React, { useState, useEffect } from 'react';
import { Card, CardBody, Typography } from "@material-tailwind/react";
import { CurrencyDollarIcon, UsersIcon, UserPlusIcon, ChartBarIcon } from "@heroicons/react/24/solid";
import { motion } from 'framer-motion';

const iconMap = {
  money: CurrencyDollarIcon,
  users: UsersIcon,
  "user-plus": UserPlusIcon,
  "chart-bar": ChartBarIcon,
};

const StatCard = ({ icon, title, value, change, changeColor }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [currentChange, setCurrentChange] = useState(change);
  const [currentChangeColor, setCurrentChangeColor] = useState(changeColor);

  const IconComponent = iconMap[icon];

  const parseValue = (val) => {
    if (typeof val === 'string') {
      // Remove currency symbol and commas, then parse
      return parseFloat(val.replace(/[^0-9.-]+/g, ""));
    }
    return val;
  };

  const formatValue = (val) => {
    // Preserve the original currency symbol (₹ in this case)
    const currencySymbol = value.match(/^[^\d]+/)?.[0] || '';
    return `${currencySymbol}${val.toLocaleString('en-IN')}`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const numericValue = parseValue(currentValue);
      const newValue = Math.max(0, numericValue + Math.floor(Math.random() * 10000) - 5000);
      const changePercentage = Math.floor(Math.random() * 10) - 5;

      setCurrentValue(formatValue(newValue));
      setCurrentChange(`${changePercentage > 0 ? '+' : ''}${changePercentage}% than yesterday`);
      setCurrentChangeColor(changePercentage > 0 ? "text-green-500" : "text-red-500");
    }, 5000);

    return () => clearInterval(interval);
  }, [currentValue]);

  return (
    <motion.div whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 300 }}>
      <Card className="overflow-hidden">
        <CardBody className="flex items-center p-4">
          <div className="rounded-full bg-blue-500 p-3 mr-4">
            <IconComponent className="h-6 w-6 text-white" />
          </div>
          <div>
            <Typography variant="small" color="blue-gray" className="font-normal mb-1">
              {title}
            </Typography>
            <Typography variant="h4" color="blue-gray" className="mb-1">
              {currentValue}
            </Typography>
            <Typography variant="small" className={currentChangeColor}>
              {currentChange}
            </Typography>
          </div>
        </CardBody>
      </Card>
    </motion.div>
  );
};

export default StatCard;