import React, { useState } from 'react';
import { Card, CardHeader, CardBody, Typography, Button, Select, Option } from "@material-tailwind/react";
import { motion } from "framer-motion";
import Alerts from './Alerts';

export default function ControlPanel() {
    const [selectedZone, setSelectedZone] = useState("");
    const [selectedValve, setSelectedValve] = useState("");

    return (
        <Card className="w-full max-w-[26rem] overflow-hidden">
            <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="m-0 p-4 bg-gradient-to-br from-green-400 to-blue-500"
            >
                <Typography variant="h5" color="white" className="mb-2">
                    Control Panel
                </Typography>
            </CardHeader>
            <CardBody className="text-center">

                <div className="flex flex-col space-y-4">
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                        <Button size="lg" color="green" ripple={true} fullWidth={true}>
                            Start Irrigation
                        </Button>
                    </motion.div>
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                        <Button size="lg" color="red" ripple={true} fullWidth={true}>
                            Stop Irrigation
                        </Button>
                    </motion.div>
                    <Select
                        label="Select Zone"
                        value={selectedZone}
                        onChange={(value) => setSelectedZone(value)}
                        color="blue"
                    >
                        <Option value="zone1-2">Zone 1 - Zone 2</Option>
                        <Option value="zone3-4">Zone 3 - Zone 4</Option>
                        <Option value="zone5-6">Zone 5 - Zone 6</Option>
                    </Select>
                    <Select
                        label="Select Valve"
                        value={selectedValve}
                        onChange={(value) => setSelectedValve(value)}
                        color="blue"
                    >
                        <Option value="valve1">Valve 1</Option>
                        <Option value="valve2">Valve 2</Option>
                        <Option value="valve3">Valve 3</Option>
                        <Option value="valve4">Valve 4</Option>
                    </Select>
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                        <Button size="lg" color="blue" ripple={true} fullWidth={true}>
                            Schedule Irrigation
                        </Button>
                    </motion.div>
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                        <Button size="lg" color="blue" ripple={true} fullWidth={true}>
                            Schedule Fertigation
                        </Button>
                    </motion.div>
                </div>
                <div className="mt-4">
                    <Alerts />
                </div>
            </CardBody>
        </Card>
    );
};
