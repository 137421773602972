import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Tabs,
  TabsHeader,
  Tab,
} from "@material-tailwind/react";
import { CheckIcon } from "@heroicons/react/24/solid";

const PRICING_DATA = [
  {
    title: "MINI",
    monthlyPrice: '500',
    annualPrice: '25,000',
    features: [
      "Farm Ecosystem Information",
      "Kiaaniot Smart Pole",
      "Integration help",
      "24/7 Platform Support",
    ],
    buttonText: "SUBSCRIBE MINI",
    highlighted: true,
  },
  {
    title: "MAX",
    monthlyPrice: '1,000',
    annualPrice: '50,000',
    features: [
      "Irrigation Automation",
      "Fertigation Automation",
      "Real Time Surveillance",
      "Disease Management",
    ],
    buttonText: "SUBSCRIBE MAX",
    highlighted: true,
  },
];

const PricingCard = ({ title, monthlyPrice, annualPrice, features, buttonText, highlighted, isAnnual }) => (
  <Card color={highlighted ? "white" : "transparent"} shadow={highlighted} className="w-full max-w-[20rem] p-8">
    <CardHeader
      floated={false}
      shadow={false}
      color="transparent"
      className="m-0 mb-8 rounded-none border-b border-white/10 pb-8 text-center"
    >
      <Typography variant="small" color="blue-gray" className="font-normal uppercase">
        {title}
      </Typography>
      <Typography variant="h1" color="blue-gray" className="mt-6 flex justify-center gap-1 text-5xl font-normal">
        <span className="mt-2 text-xl">₹</span>{isAnnual ? annualPrice : monthlyPrice}{" "}
        <span className="self-end text-xl">{isAnnual ? "/device" : "/mo"}</span>
      </Typography>
    </CardHeader>
    <CardBody className="p-0">
      <ul className="flex flex-col gap-4">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center gap-4">
            <CheckIcon className="h-5 w-5 text-blue-600" />
            <Typography className="font-normal">{feature}</Typography>
          </li>
        ))}
      </ul>
    </CardBody>
    <CardFooter className="mt-12 p-0">
      <Button
        size="lg"
        color={highlighted ? "gray" : "blue-gray"}
        className="hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
        ripple={false}
        fullWidth={true}
      >
        {buttonText}
      </Button>
    </CardFooter>
  </Card>
);

const Pricing = () => {
  const [activeTab, setActiveTab] = useState("monthly");
  const isAnnual = activeTab === "annual";

  return (
    <div className="container mx-auto px-4 py-16 flex flex-col items-center">
      <div className="mb-12">
        {/* <Typography variant="h5" color="blue-gray" className="mb-2 text-center">
          Pricing
        </Typography> */}
        <Typography variant="h2" color="white" className="mb-2 text-center">
          See Our Pricing
        </Typography>
        <Typography color="blue-gray" className="max-w-3xl text-center font-bold">
          Pricing for Kiaaniot - MINI & MAX plans.
        </Typography>
      </div>

      <div className="flex justify-center mb-8">
        <Tabs value={activeTab} className="w-max">
          <TabsHeader
            className="bg-transparent border border-white/25"
            indicatorProps={{
              className: "bg-white shadow-none !text-white",
            }}
          >
            <Tab
              key="monthly"
              value="monthly"
              onClick={() => setActiveTab("monthly")}
              className={activeTab === "monthly" ? "text-black" : "text-black"}
            >
              Monthly
            </Tab>
            <Tab
              key="annual"
              value="annual"
              onClick={() => setActiveTab("annual")}
              className={activeTab === "annual" ? "text-black" : "text-black"}
            >
              Annual
            </Tab>
          </TabsHeader>
        </Tabs>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 justify-items-center">
        {PRICING_DATA.map((plan, index) => (
          <PricingCard key={index} {...plan} isAnnual={isAnnual} />
        ))}
      </div>
    </div>
  );
}

export default Pricing;