import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  Input,
  Textarea,
  Radio,
  Select,
  Option,
  Alert,
  Spinner,
} from "@material-tailwind/react";
import { motion } from "framer-motion";
import FileUpload from './FileUpload';
import { DELETE } from "../../utils/ApiHandler";
import Stepper from './Stepper';

const HEADINGS = ['Land Acquisition', 'Climatic Conditions', 'Fencing and Basic Infrastructure', 'Legal Opinion', 'Sample Test'];

const OnboardTenant = () => {
  const { token, tenantId } = useSelector((state) => state.login);
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [uploadProgress, setUploadProgress] = useState({});

  useEffect(() => {
    fetchQuestionsAndAnswers();
  }, []);

  const fetchQuestionsAndAnswers = async () => {
    try {
      setLoading(true);
      const questionsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/questions`);
      const fetchedQuestions = questionsResponse.data || [];
      setQuestions(fetchedQuestions);

      const submissionResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/submissions/latest?tenantId=${tenantId}`);
      const latestSubmission = submissionResponse.data || null;

      if (latestSubmission && latestSubmission.answers) {
        const initialAnswers = {};
        latestSubmission.answers.forEach(answer => {
          initialAnswers[answer.question?._id] = {
            answer: answer.answer,
            files: answer.files || []
          };
        });
        setAnswers(initialAnswers);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (questionId, answer) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: { ...prev[questionId], answer }
    }));
  };

  const handleFileUpload = (questionId, uploadedFiles) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: {
        answer: prev[questionId]?.answer || '',
        files: [...(prev[questionId]?.files || []), ...uploadedFiles]
      }
    }));
    setUploadProgress(prev => ({ ...prev, [questionId]: 0 }));
  };

  const handleFileDelete = async (questionId, fileIndex) => {
    const fileToDelete = answers[questionId].files[fileIndex];
    try {
      await DELETE(`${process.env.REACT_APP_API_URL}/api/v1/common/file/remove?blobName=${fileToDelete.blobName}`, {}, token);
      setAnswers(prev => ({
        ...prev,
        [questionId]: {
          ...prev[questionId],
          files: prev[questionId].files.filter((_, index) => index !== fileIndex)
        }
      }));
      setSuccessMessage('File deleted successfully');
    } catch (error) {
      console.error('Error deleting file:', error);
      setError('Failed to delete file. Please try again.');
    }
  };

  const handleSave = async () => {
    try {
      const formattedAnswers = Object.keys(answers)
        .filter(questionId => {
          const question = questions.find(q => q._id === questionId);
          return HEADINGS.slice(0, 3).includes(question?.heading);
        })
        .map(questionId => ({
          question: questionId,
          answer: answers[questionId].answer,
          files: answers[questionId]?.files?.map(file => file?.blobName) || []
        }));

      await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/submissions/save?tenantId=${tenantId}`, {
        answers: formattedAnswers,
        headings: HEADINGS.slice(0, 3)
      });

      setSuccessMessage('Data saved and email sent successfully!');
      handleNext();
    } catch (error) {
      console.error('Error saving data:', error);
      setError('Failed to save data. Please try again later.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formattedAnswers = Object.keys(answers).map(questionId => ({
        question: questionId,
        answer: answers[questionId].answer,
        files: answers[questionId]?.files?.map(file => file?.blobName) || []
      }));

      await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/submissions/submit?tenantId=${tenantId}`, {
        answers: formattedAnswers
      });

      const fourthHeadingAnswers = formattedAnswers.filter(answer =>
        questions.find(q => q._id === answer.question)?.heading === HEADINGS[3]
      );

      const fifthHeadingAnswers = formattedAnswers.filter(answer =>
        questions.find(q => q._id === answer.question)?.heading === HEADINGS[4]
      );

      const combinedAnswers = [...fourthHeadingAnswers, ...fifthHeadingAnswers];

      await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/submissions/submit`, {
        answers: combinedAnswers,
        headings: [HEADINGS[3], HEADINGS[4]]
      });

      setSuccessMessage('Form submitted successfully and email sent!');
      navigate('/agronomist');
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('Failed to submit form. Please try again later.');
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderMediaPreview = (files, questionId) => {
    return files.map((file, index) => {
      const src = file.sasUrl;
      return (
        <motion.div
          key={index}
          className="relative inline-block m-1"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <Button
            size="sm"
            color="red"
            className="absolute top-1 right-1 z-10"
            onClick={() => handleFileDelete(questionId, index)}
          >
            &times;
          </Button>
          {src.includes('.mp4') || src.includes('.mpeg') ? (
            <video src={src} controls className="w-48 h-48 object-cover rounded-lg" />
          ) : src.includes('.pdf') ? (
            <iframe src={src} className="w-48 h-48 rounded-lg" title={`pdf-preview-${index}`} />
          ) : (
            <img src={src} alt="" className="w-48 h-48 object-cover rounded-lg" />
          )}
        </motion.div>
      );
    });
  };

  const renderQuestionsByHeading = (heading) => {
    const filteredQuestions = questions.filter(q => q.heading === heading);
    const groupedQuestions = filteredQuestions.reduce((acc, question) => {
      if (!acc[question.subheading]) acc[question.subheading] = [];
      acc[question.subheading].push(question);
      return acc;
    }, {});

    return Object.entries(groupedQuestions).map(([subheading, subQuestions]) => (
      <motion.div
        key={subheading}
        className="mt-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h5" color="blue-gray" className="mb-4">
          {subheading}
        </Typography>
        {subQuestions.map(question => (
          <div key={question._id} className="mb-6">
            <Typography variant="h6" color="blue-gray" className="mb-2">
              {question.text}
            </Typography>
            {question.type === 'checkbox' && (
              <div className="flex gap-4">
                <Radio
                  name={question._id}
                  label="Yes"
                  value="Yes"
                  checked={answers[question._id]?.answer === 'Yes'}
                  onChange={(e) => handleInputChange(question._id, e.target.value)}
                />
                <Radio
                  name={question._id}
                  label="No"
                  value="No"
                  checked={answers[question._id]?.answer === 'No'}
                  onChange={(e) => handleInputChange(question._id, e.target.value)}
                />
              </div>
            )}
            {question.type === 'dropdown' && (
              <Select
                label="Select an option"
                value={answers[question._id]?.answer || ''}
                onChange={(value) => handleInputChange(question._id, value)}
              >
                {question.options.map((option, index) => (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            )}
            {question.type === 'textarea' && (
              <Textarea
                label="Enter your answer"
                value={answers[question._id]?.answer || ''}
                onChange={(e) => handleInputChange(question._id, e.target.value)}
              />
            )}
            {question.type === 'files' && (
              <div>
                {answers[question._id]?.files && answers[question._id].files.length > 0 && (
                  <div className="mt-4">
                    <Typography variant="h6" color="blue-gray" className="mb-2">
                      Existing files:
                    </Typography>
                    {uploadProgress[question._id] > 0 && uploadProgress[question._id] < 100 && (
                      <div className="w-full mt-2">
                        <progress
                          className="w-full h-2 bg-blue-200 rounded-full overflow-hidden"
                          value={uploadProgress[question._id]}
                          max="100"
                        />
                      </div>
                    )}
                    <div className="flex flex-wrap">
                      {renderMediaPreview(answers[question._id].files, question._id)}
                    </div>
                  </div>
                )}
                <FileUpload
                  path="dataforms"
                  id={question._id}
                  onChange={(uploadedFiles) => handleFileUpload(question._id, uploadedFiles)}
                  onProgress={(progress) => setUploadProgress(prev => ({ ...prev, [question._id]: progress }))}
                />
                <Textarea
                  label="Additional comments"
                  value={answers[question._id]?.answer || ''}
                  onChange={(e) => handleInputChange(question._id, e.target.value)}
                  className="mt-4"
                />
              </div>
            )}
          </div>
        ))}
      </motion.div>
    ));
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner className="h-12 w-12" />
      </div>
    );
  }

  return (
    <div className="w-full px-4 py-8">
      <Card className="w-full">
        <CardHeader floated={false} className="bg-blue-500">
          <Typography variant="h3" color="white" className="mb-2 text-center">
            Onboard Tenant - {tenantId}
          </Typography>
        </CardHeader>
        <CardBody>
          {error && (
            <Alert color="red" className="mb-4">
              {error}
            </Alert>
          )}
          {successMessage && (
            <Alert color="green" className="mb-4">
              {successMessage}
            </Alert>
          )}

          <Stepper activeStep={activeStep} headings={HEADINGS} />

          <form onSubmit={(e) => e.preventDefault()}>
            {renderQuestionsByHeading(HEADINGS[activeStep])}

            <div className="flex justify-between mt-8">
              <Button
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                Back
              </Button>
              {activeStep === HEADINGS.length - 1 ? (
                <Button onClick={handleSubmit} color="green">
                  Submit
                </Button>
              ) : activeStep === 2 ? (
                <Button onClick={handleSave} color="blue">
                  Save
                </Button>
              ) : (
                <Button onClick={handleNext} color="blue">
                  Next
                </Button>
              )}
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default OnboardTenant;