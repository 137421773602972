import React from 'react';
import {
  Card,
  CardBody,
  Typography,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { ShoppingCartIcon, CreditCardIcon, UsersIcon } from "@heroicons/react/24/solid";

const FAQ_DATA = [
  {
    icon: ShoppingCartIcon,
    question: "How do I subscribe?",
    answer: "Subscribing to our AI-IoT agritech platform involves a detailed onboarding process to ensure the best fit for your farm. Start by contacting us to express your interest. Our team will conduct a checklist review based on specific criteria related to your farm. Once your farm meets our requirements, we will proceed with onboarding you onto the platform.You can choose between our Mini Plan for smaller farms and our Max Plan, which includes advanced features like irrigation and fertigation automation, as well as disease management tools to optimize your agricultural practices."
  },
  {
    icon: CreditCardIcon,
    question: "How can i make the payment?",
    answer: "Making a payment for your subscription is easy and secure. Once your onboarding is complete and you receive your confirmation email, you will be directed to our payment portal. We accept various payment methods, including credit/debit cards and bank transfers, to accommodate your preferences.Simply log in to your account, select your chosen plan, and follow the prompts to enter your payment details. All transactions are encrypted for your security. After processing, you’ll receive a confirmation of your payment, and your subscription will be activated immediately."
  },
  {
    icon: UsersIcon,
    question: "How do I request a demo?",
    answer: "Requesting a demo of our AI-IoT agritech platform is simple! Just visit our Platform and navigate to the “Request a Demo” section. Fill out the form with your contact information and a brief description of your farm's needs. Our team will review your request and get in touch to schedule a personalized demo at a convenient time for you.During the demo, you’ll have the opportunity to see our platform in action, explore its features, and ask any questions you may have. We’re excited to help you discover how our solutions can enhance your agricultural practices!"
  }
];

const FAQ = () => {
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Typography variant="h2" color="blue-gray" className="mb-2 text-center">
        Frequently asked questions
      </Typography>
      <Typography color="white" className="mb-8 text-center">
      </Typography>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {FAQ_DATA.map((item, index) => (
          <Card key={index} className="mt-6">
            <CardBody>
              <div className="mb-4">
                {React.createElement(item.icon, { className: "h-6 w-6 text-blue-gray-500" })}
              </div>
              <Typography variant="h5" color="blue-gray" className="mb-2">
                {item.question}
              </Typography>
              <Typography color="gray" className="font-normal">
                {item.answer}
              </Typography>
            </CardBody>
          </Card>
        ))}
      </div>

      {/* <div className="mt-8">
        <Accordion open={open === 1}>
          <AccordionHeader onClick={() => handleOpen(1)} className='text-black'>How do I subscribe?</AccordionHeader>
          <AccordionBody className='text-white'>
            We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play this game of life you need to appreciate every moment. A lot of people don't appreciate the moment until it's passed.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 2} className='text-black'>
          <AccordionHeader onClick={() => handleOpen(2)} className='text-black'>How can i make the payment?</AccordionHeader>
          <AccordionBody className='text-white'>
            It really matters and then like it really doesn't matter. What matters is the people who are sparked by it. And the people who are like offended by it, it doesn't matter. Because it's about motivating the doers. Because I'm here to follow my dreams and inspire other people to follow their dreams, too. We're not always in the position that we want to be at.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 3} >
          <AccordionHeader onClick={() => handleOpen(3)} className='text-black'>How do I request a demo?</AccordionHeader>
          <AccordionBody className='text-white'>
            We're not always in the position that we want to be at. We're constantly growing. We're constantly making mistakes. We're constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play this game of life you need to appreciate every moment. A lot of people don't appreciate the moment until it's passed.
          </AccordionBody>
        </Accordion>
      </div> */}
    </div>
  );
}

export default FAQ;