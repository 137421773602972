import React from 'react';
import { Typography } from "@material-tailwind/react";
import DataTable from '../DataTable/DataTable';
import { GET, DELETE } from '../../utils/ApiHandler';
import { useSelector } from 'react-redux';

const TenantList = () => {
  const TABLE_HEAD = ["Tenant Name", "Tenant Code", "Subscription Start Date", "Location", "Plantation", "Cluster", "Actions"];
  const { token, tenantId } = useSelector((state) => state.login);

  const fetchTenants = async () => {
    const data = await GET(`${process.env.REACT_APP_API_URL}/api/v1/tenants/fulllist`, token);
    return data?.data?.tenants || [];
  };

  const deleteTenant = async (tenantId) => {
    return await DELETE(`${process.env.REACT_APP_API_URL}/api/v1/tenants/delete`, { tenantId: tenantId }, token);
  };

  const renderTenantRow = (tenant) => (
    <>
      <td className="p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          {tenant.project_name}
        </Typography>
      </td>
      <td className="p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          {tenant.project_code}
        </Typography>
      </td>
      <td className="p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          {tenant.project_start_date}
        </Typography>
      </td>
      <td className="p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          {tenant.location}
        </Typography>
      </td>
      <td className="p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          {tenant.plantation_name}
        </Typography>
      </td>
      <td className="p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          {tenant.cluster}
        </Typography>
      </td>
    </>
  );

  return (
    <DataTable
      title="Tenant"
      subtitle="Manage your organization's Tenants"
      addButtonText="Initialize Tenant"
      addButtonLink="/admin/tenants/add"
      tableHead={TABLE_HEAD}
      fetchData={fetchTenants}
      deleteItem={deleteTenant}
      renderTableRow={renderTenantRow}
      searchFields={['project_name']}
    />
  );
};

export default TenantList;