import React, { useState } from 'react';
import { Card, CardBody, Typography, Button, CardHeader } from "@material-tailwind/react";
import { motion } from "framer-motion";
import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";

export default function DownloadReports() {
  const [generating, setGenerating] = useState(false);

  const handleGenerateReport = (type) => {
    setGenerating(true);
    setTimeout(() => {
      setGenerating(false);
      // Simulating report generation and download
      const link = document.createElement("a");
      link.href = "#";
      link.download = `${type}_report.pdf`;
      link.click();
    }, 2000);
  };

  return (
    <Card className="w-full overflow-hidden">
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="m-0 p-6 bg-gradient-to-br from-green-400 to-blue-500"
      >
        <Typography variant="h3" color="white" className="mb-2">
          Download Reports
        </Typography>
      </CardHeader>
      <CardBody>
        <div className="flex flex-col space-y-4">
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button
              size="lg"
              color="blue"
              ripple={true}
              fullWidth={true}
              onClick={() => handleGenerateReport("weekly")}
              disabled={generating}
              className="flex items-center justify-center"
            >
              <DocumentDuplicateIcon className="h-5 w-5 mr-2" />
              Generate Weekly Report
            </Button>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button
              size="lg"
              color="green"
              ripple={true}
              fullWidth={true}
              onClick={() => handleGenerateReport("monthly")}
              disabled={generating}
              className="flex items-center justify-center"
            >
              <DocumentDuplicateIcon className="h-5 w-5 mr-2" />
              Generate Monthly Report
            </Button>
          </motion.div>
        </div>
        {generating && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="mt-4 text-center text-white"
          >
            Generating report... Please wait.
          </motion.div>
        )}
      </CardBody>
    </Card>
  );
};