import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Typography } from "@material-tailwind/react";
import { motion } from "framer-motion";
import { CloudIcon, SunIcon, BeakerIcon } from "@heroicons/react/24/solid";
import { FaTint } from 'react-icons/fa';

export default function IoTSensorReadings() {
  const [readings, setReadings] = useState({
    moisture: 60,
    rainfall: 2.5,
    temperature: 25,
    humidity: 45,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setReadings({
        moisture: Math.floor(Math.random() * 20) + 50,
        rainfall: (Math.random() * 5).toFixed(1),
        temperature: Math.floor(Math.random() * 10) + 20,
        humidity: Math.floor(Math.random() * 20) + 40,
      });
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const SensorReading = ({ icon: Icon, label, value, unit }) => (
    <motion.div
      className="text-center"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <Icon className="w-16 h-16 mx-auto mb-2 text-black" />
      <Typography variant="h6" color="black">
        {label}
      </Typography>
      <Typography variant="h4" color="black" className="font-bold">
        {value}
        {unit}
      </Typography>
    </motion.div>
  );

  return (
    <Card className="w-full overflow-hidden">
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="m-0 p-4 bg-gradient-to-br from-green-400 to-blue-500"
      >
        <Typography variant="h5" color="white" className="mb-2">
          IoT Sensor Readings
        </Typography>
      </CardHeader>
      <CardBody>

        <div className="grid grid-cols-2 gap-4">
          <SensorReading icon={FaTint} label="Moisture" value={readings.moisture} unit="%" />
          <SensorReading icon={CloudIcon} label="Rainfall" value={readings.rainfall} unit="mm" />
          <SensorReading icon={SunIcon} label="Temperature" value={readings.temperature} unit="°C" />
          <SensorReading icon={BeakerIcon} label="Humidity" value={readings.humidity} unit="%" />
        </div>
      </CardBody>
    </Card>
  );
};