import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Spinner,
} from "@material-tailwind/react";
import { motion, AnimatePresence } from "framer-motion";
import dayjs from "dayjs";
import { GET } from "../utils/ApiHandler";

const calculateCurrentWeek = (startDate) => {
  const start = dayjs(startDate);
  const now = dayjs();
  return Math.floor(now.diff(start, "day") / 7) + 1;
};

const PlantationProcessHistory = () => {
  const { token, tenantId } = useSelector((state) => state.login);
  const [projectStartDate, setProjectStartDate] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(1);
  const [processLogData, setProcesslogData] = useState({});
  const totalWeeks = 55;
  const [processData, setProcessData] = useState([]);
  const [weekOffset, setWeekOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const weeksPerPage = isMobile ? 5 : 8;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchProjectStartDate = useCallback(async () => {
    try {
      setLoading(true);
      const response = await GET(`${process.env.REACT_APP_API_URL}/api/v1/tenants/list?tenantId=${tenantId}`, token);
      const startDate = response.data.tenants[0]?.project_start_date;
      setProjectStartDate(startDate || null);
      return startDate;
    } catch (error) {
      console.error("Error fetching project start date:", error);
    } finally {
      setLoading(false);
    }
  }, [token, tenantId]);

  const fetchProcessData = useCallback(async (week) => {
    try {
      setLoading(true);
      const response = await GET(`${process.env.REACT_APP_API_URL}/api/v1/process/history?week=${week}`, token);
      setProcessData(response.data.process || []);
    } catch (error) {
      console.error("Error fetching process data:", error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    const initializeData = async () => {
      const startDate = await fetchProjectStartDate();
      if (startDate) {
        const currentWeekNumber = calculateCurrentWeek(startDate);
        setCurrentWeek(currentWeekNumber);
        setWeekOffset(Math.floor((currentWeekNumber - 1) / weeksPerPage) * weeksPerPage);
        await fetchProcessData(currentWeekNumber);
      }
    };
    initializeData();
  }, [fetchProjectStartDate, fetchProcessData, weeksPerPage]);


  const fetchProcesslogData = useCallback(
    async (processId, tenantId, week) => {
      try {
        const response = await GET(
          `${process.env.REACT_APP_API_URL}/api/v1/processlogs/history?processId=${processId}&tenantId=${tenantId}&week=${week}`,
          token
        );
        if (response.success) {
          setProcesslogData(prevState => ({
            ...prevState,
            [processId]: response.data.processlog[0] || null
          }));
        }
      } catch (error) {
        console.error("Error fetching process log data:", error);
        // setAlertMessage("Failed to fetch process log data");
        // setAlertColor("red");
        // setAlertOpen(true);
      }
    },
    [token]
  );

  useEffect(() => {
    if (currentWeek !== null && processData.length > 0) {
      processData.forEach(process => {
        fetchProcesslogData(process._id, tenantId, currentWeek);
      });
    }
  }, [currentWeek, processData, fetchProcesslogData, tenantId]);

  useEffect(() => {
    if (projectStartDate) {
      fetchProcessData(currentWeek);
    }
  }, [currentWeek, projectStartDate, fetchProcessData]);

  const handleWeekChange = (week) => {
    setCurrentWeek(week);
  };

  const handleReturnToCurrentWeek = useCallback(() => {
    if (projectStartDate) {
      const currentWeekNumber = calculateCurrentWeek(projectStartDate);
      setCurrentWeek(currentWeekNumber);
      setWeekOffset(Math.floor((currentWeekNumber - 1) / weeksPerPage) * weeksPerPage);
      fetchProcessData(currentWeekNumber);
    }
  }, [projectStartDate, fetchProcessData, weeksPerPage]);

  const handleNextWeeks = () => {
    setWeekOffset((prev) => Math.min(prev + weeksPerPage, totalWeeks - weeksPerPage));
    setCurrentWeek(weekOffset + weeksPerPage + 1);
  };

  const handlePreviousWeeks = () => {
    setWeekOffset((prev) => Math.max(prev - weeksPerPage, 0));
    setCurrentWeek(weekOffset - weeksPerPage + 1);
  };

  const weekTabs = [];
  for (let i = 0; i < weeksPerPage; i++) {
    const weekNumber = weekOffset + i + 1;
    if (weekNumber > totalWeeks) break;

    const weekStart = dayjs(projectStartDate).add((weekNumber - 1) * 7, "day");
    const weekEnd = weekStart.add(6, "day");
    const weekRange = `${weekStart.format("MMM DD")} - ${weekEnd.format("MMM DD")}`;

    weekTabs.push({
      label: `Week ${weekNumber}`,
      value: weekNumber.toString(),
      desc: weekRange,
    });
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-400 to-blue-500 p-8 rounded-xl">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h2" color="white" className="mb-4 text-center">
          {projectStartDate ? "Plantation Process History" : "Please select a Tenant"}
        </Typography>
        {projectStartDate && (
          <Card className="w-full shadow-xl">
            <CardHeader floated={false} className="h-24">
              <div className="flex justify-between items-center h-full px-4">
                <Button
                  size="sm"
                  variant="text"
                  className="flex items-center gap-2"
                  onClick={handlePreviousWeeks}
                  disabled={weekOffset === 0}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="h-4 w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                    />
                  </svg>
                  Previous
                </Button>
                <Button
                  size="sm"
                  variant="text"
                  className="flex items-center gap-2"
                  onClick={handleNextWeeks}
                  disabled={weekOffset + weeksPerPage >= totalWeeks}
                >
                  Next
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="h-4 w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                    />
                  </svg>
                </Button>
              </div>
            </CardHeader>
            <CardBody className="px-0 pt-0">
              <Tabs value={currentWeek.toString()} className="overflow-visible">
                <TabsHeader className="relative z-0 ">
                  {weekTabs.map(({ label, value, desc }) => (
                    <Tab
                      key={value}
                      value={value}
                      onClick={() => handleWeekChange(parseInt(value))}
                      className="w-48"
                    >
                      <div className="flex flex-col items-center gap-1">
                        {label}
                        <span className="text-xs font-normal">{desc}</span>
                      </div>
                    </Tab>
                  ))}
                </TabsHeader>
                <TabsBody
                  className="!overflow-x-hidden !overflow-y-visible"
                  animate={{
                    initial: { x: 400 },
                    mount: { x: 0 },
                    unmount: { x: -400 },
                  }}
                >
                  <TabPanel value={currentWeek.toString()} className="py-0">
                    <AnimatePresence mode="wait">
                      {loading ? (
                        <motion.div
                          key="loading"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="flex justify-center items-center h-64"
                        >
                          <Spinner className="h-12 w-12" />
                        </motion.div>
                      ) : processData.length === 0 ? (
                        <motion.div
                          key="no-data"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="text-center py-8"
                        >
                          <Typography variant="h5">
                            No Plantation Process data available for this week
                          </Typography>
                        </motion.div>
                      ) : (
                        <motion.div
                          key="process-data"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          <Typography variant="h4" color="blue-gray" className="pt-2 mb-4 text-center">
                            {processData[0]?.title || "Plantation Process"}
                          </Typography>
                          {processData.map((process, index) => (
                            <motion.div
                              key={process._id}
                              initial={{ opacity: 0, y: 20 }}
                              animate={{ opacity: 1, y: 0 }}
                              transition={{ delay: index * 0.1 }}
                            >
                              <Card className="mb-4 overflow-hidden">
                                <CardBody>
                                  <Typography variant="h5" color="blue-gray" className="mb-2">
                                    {process.subtitle}
                                  </Typography>
                                  <Typography>{process.description}</Typography>
                                  {processLogData[process._id]?.processLog && (
                                    <div className="mt-4">
                                      <Typography variant="h6" color="blue-gray" className="mb-2">
                                        Process Log
                                      </Typography>
                                      <Typography>{processLogData[process._id].processLog}</Typography>
                                    </div>
                                  )}
                                </CardBody>
                                {processLogData[process._id]?.media && processLogData[process._id].media.length > 0 && (
                                  <CardFooter className="pt-0">
                                    <Typography variant="h6" color="blue-gray" className="mb-2">
                                      Media
                                    </Typography>
                                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                                      {processLogData[process._id]?.media?.map((media, mediaIndex) => (
                                        <div key={mediaIndex} className="relative">
                                          {media?.sasUrl?.toLowerCase().endsWith('.mp4') ? (
                                            <video
                                              src={media.sasUrl}
                                              controls
                                              className="w-full h-full object-cover rounded-lg"
                                            />
                                          ) : (
                                            <img
                                              src={media.sasUrl}
                                              alt={`Process media ${mediaIndex + 1}`}
                                              className="w-full h-full object-cover rounded-lg"
                                            />
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  </CardFooter>
                                )}
                              </Card>
                            </motion.div>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </TabPanel>
                </TabsBody>
              </Tabs>
            </CardBody>
            {/* <CardFooter className="pt-0">
              <Button
                size="lg"
                variant="gradient"
                color="green"
                className="mx-auto"
                onClick={handleReturnToCurrentWeek}
              >
                Return to Current Week
              </Button>
            </CardFooter> */}
          </Card>
        )}
      </motion.div>
    </div>
  );
};

export default PlantationProcessHistory;