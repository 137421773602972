import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    Button,
    Typography,
    Alert,
} from "@material-tailwind/react";
import {
    UserCircleIcon,
    EnvelopeIcon,
    PhoneIcon,
    KeyIcon,
    UserIcon
} from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import { GET, PUT, POST } from '../../utils/ApiHandler';

const UserForm = () => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const { token } = useSelector((state) => state.login);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        user_name: '',
        email: '',
        mobile: '',
        role: '',
        password: ''
    });
    const [alert, setAlert] = useState({ show: false, message: '', color: 'green' });

    useEffect(() => {
        if (userId) {
            const getUser = async () => {
                let data = await GET(`${process.env.REACT_APP_API_URL}/api/v1/users/${userId}`, token);
                let { first_name, last_name, user_name, email, mobile, role } = data.data.user;
                setFormData({ first_name, last_name, user_name, email, mobile, role, password: '' });
            };
            getUser();
        }
    }, [token, userId]);

    const userSubmitHandler = async (e) => {
        e.preventDefault();
        let data;
        if (userId) {
            data = await PUT(`${process.env.REACT_APP_API_URL}/api/v1/users/${userId}`, formData, token);
        } else {
            data = await POST(`${process.env.REACT_APP_API_URL}/api/v1/users/create`, formData, token);
        }
        if (data.success) {
            setAlert({
                show: true,
                message: `Successfully ${userId ? 'updated' : 'added'} User!`,
                color: 'green'
            });
            setTimeout(() => navigate('/admin/users'), 2000);
        } else {
            setAlert({
                show: true,
                message: `Error ${userId ? 'updating' : 'adding'} User: ${data.message}`,
                color: 'red'
            });
        }
    };

    const cancelHandler = () => {
        navigate('/admin/users');
    };

    const textInputHandler = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="min-h-screen p-8 bg-gradient-to-br from-green-400 to-blue-500 flex items-center justify-center rounded-xl"
        >
            <Card className="w-full max-w-[40rem] bg-white bg-opacity-90 backdrop-blur-md">
                <CardHeader
                    color="blue"
                    floated={false}
                    shadow={false}
                    className="m-0 grid place-items-center rounded-b-none py-8 px-4 text-center"
                >
                    <div className="mb-4 rounded-full border border-white/10 bg-white/10 p-6 text-white">
                        <UserIcon className="h-10 w-10" />
                    </div>
                    <Typography variant="h4" color="white">
                        {userId ? 'Edit' : 'Add'} User
                    </Typography>
                </CardHeader>
                <CardBody className="flex flex-col gap-4">
                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.1 }}
                        className="flex gap-4"
                    >
                        <Input
                            label="First Name"
                            size="lg"
                            icon={<UserCircleIcon className="h-5 w-5" />}
                            value={formData.first_name}
                            onChange={textInputHandler}
                            id="first_name"
                        />

                    </motion.div>

                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.2 }}
                    >

                        <Input
                            label="Last Name"
                            size="lg"
                            icon={<UserCircleIcon className="h-5 w-5" />}
                            value={formData.last_name}
                            onChange={textInputHandler}
                            id="last_name"
                        />
                    </motion.div>

                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.2 }}
                    >
                        <Input
                            label="Username"
                            size="lg"
                            icon={<UserCircleIcon className="h-5 w-5" />}
                            value={formData.user_name}
                            onChange={textInputHandler}
                            id="user_name"
                        />
                    </motion.div>
                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.3 }}
                    >
                        <Input
                            type="email"
                            label="Email"
                            size="lg"
                            icon={<EnvelopeIcon className="h-5 w-5" />}
                            value={formData.email}
                            onChange={textInputHandler}
                            id="email"
                        />
                    </motion.div>
                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.4 }}
                    >
                        <Input
                            label="Mobile"
                            size="lg"
                            icon={<PhoneIcon className="h-5 w-5" />}
                            value={formData.mobile}
                            onChange={textInputHandler}
                            id="mobile"
                        />
                    </motion.div>
                    <motion.div
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.4 }}
                    >
                        <Input
                            label="Role"
                            size="lg"
                            icon={<UserIcon className="h-5 w-5" />}
                            value={formData.role}
                            onChange={textInputHandler}
                            id="role"
                        />
                    </motion.div>
                    {(userId || !userId) && (
                        <motion.div
                            initial={{ x: -20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ delay: 0.5 }}
                        >
                            <Input
                                type="password"
                                label={userId ? "Update Password" : "Password"}
                                size="lg"
                                icon={<KeyIcon className="h-5 w-5" />}
                                value={formData.password}
                                onChange={textInputHandler}
                                id="password"
                            />
                        </motion.div>
                    )}
                </CardBody>
                <CardFooter className="pt-0">
                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                    >
                        <Button
                            variant="gradient"
                            color="green"
                            fullWidth
                            onClick={userSubmitHandler}
                        >
                            {userId ? 'Update' : 'Save'}
                        </Button>
                    </motion.div>
                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        className="mt-4"
                    >
                        <Button
                            variant="outlined"
                            color="red"
                            fullWidth
                            onClick={cancelHandler}
                        >
                            Cancel
                        </Button>
                    </motion.div>
                </CardFooter>
            </Card>
            {alert.show && (
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    className="fixed top-4 right-4 z-50"
                >
                    <Alert
                        color={alert.color}
                        dismissible={{
                            onClose: () => setAlert({ ...alert, show: false }),
                        }}
                    >
                        {alert.message}
                    </Alert>
                </motion.div>
            )}
        </motion.div>
    );
};

export default UserForm;