import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { Carousel, IconButton } from "@material-tailwind/react";

const images = [
  'https://images.unsplash.com/photo-1560493676-04071c5f467b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80',
  'https://images.unsplash.com/photo-1625246333195-78d9c38ad449?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
  'https://images.unsplash.com/photo-1530836369250-ef72a3f5cda8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
];

const Gallery = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    setTheme(isDarkMode ? 'dark' : 'light');
  }, []);

  const cardSpring = useSpring({
    scale: isExpanded ? 1.5 : 1,
    config: { mass: 1, tension: 280, friction: 60 }
  });

  return (
    <animated.div
      style={cardSpring}
      className={`relative w-full max-w-[68rem] overflow-hidden rounded-xl bg-gradient-to-br from-green-400 to-blue-500 p-1 shadow-2xl transition-all duration-300 ${isExpanded ? 'fixed inset-0 z-50 max-w-none' : ''}`}
    >
      <div className="mt-4 p-4">
        <h2 className="text-2xl font-bold text-white mb-2">Agri Gallery</h2>
        {/* <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setIsExpanded(!isExpanded)}
          className="bg-white text-green-500 px-4 py-2 rounded-full shadow-md"
        >
          {isExpanded ? <FaCompress /> : <FaExpand />}
        </motion.button> */}
      </div>
      <Carousel
        transition={{ duration: 0.5 }}
        className="rounded-xl"
        prevArrow={({ handlePrev }) => (
          <IconButton
            variant="text"
            color="white"
            size="lg"
            onClick={handlePrev}
            className="!absolute top-2/4 left-4 -translate-y-2/4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </IconButton>
        )}
        nextArrow={({ handleNext }) => (
          <IconButton
            variant="text"
            color="white"
            size="lg"
            onClick={handleNext}
            className="!absolute top-2/4 !right-4 -translate-y-2/4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
              />
            </svg>
          </IconButton>
        )}
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`gallery-image-${index}`}
            className="h-full w-full object-cover"
          />
        ))}
      </Carousel>
    </animated.div>
  );
};

export default Gallery;
