import React, { useState } from 'react';
import {
  Card,
  CardBody,
  Typography,
  Button,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { motion } from "framer-motion";
import { ChartPieIcon, CloudIcon, CpuChipIcon, LightBulbIcon } from "@heroicons/react/24/outline";

const FeatureCard = ({ icon, title, description }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="w-full md:w-1/2 lg:w-1/4 p-4"
    >
      <Card className="h-full shadow-lg hover:shadow-xl transition-shadow duration-300">
        <CardBody className="flex flex-col items-center text-center">
          {icon}
          <Typography variant="h5" color="blue-gray" className="mb-2 mt-4">
            {title}
          </Typography>
          <Typography color="gray" className="font-normal">
            {description}
          </Typography>
        </CardBody>
      </Card>
    </motion.div>
  );
};

const features = [
  {
    icon: <ChartPieIcon className="w-12 h-12 text-green-500" />,
    title: "Data Analytics",
    description: "Gain insights from your farm data to make informed decisions.",
  },
  {
    icon: <CloudIcon className="w-12 h-12 text-blue-500" />,
    title: "Weather Forecasting",
    description: "Accurate predictions to plan your farming activities effectively.",
  },
  {
    icon: <CpuChipIcon className="w-12 h-12 text-purple-500" />,
    title: "IoT Integration",
    description: "Connect and monitor your farm in real-time.",
  },
  {
    icon: <LightBulbIcon className="w-12 h-12 text-yellow-500" />,
    title: "AI Recommendations",
    description: "Get AI-powered suggestions for optimal crop management.",
  },
];

const Features = () => {
  const [activeTab, setActiveTab] = useState("features");

  return (
    <section className="py-20">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <Typography variant="h2" color="blue-gray" className="mb-4">
            Refreshing AgriTech Solutions
          </Typography>
          <Typography variant="lead" color="white" className="max-w-2xl mx-auto">
            We're constantly innovating to provide you with the best tools for modern farming. Our features are designed to help you grow, adapt, and succeed in the ever-changing agricultural landscape.
          </Typography>
        </div>

        <Tabs value={activeTab} className="mb-12">
          <TabsHeader
            className="bg-transparent"
            indicatorProps={{
              className: "bg-white shadow-none !text-white",
            }}
          >
            <Tab value="features" onClick={() => setActiveTab("features")}>
              Features
            </Tab>
            <Tab value="details" onClick={() => setActiveTab("details")}>
              Details
            </Tab>
          </TabsHeader>
          <TabsBody animate={{
            initial: { y: 250 },
            mount: { y: 0 },
            unmount: { y: 250 },
          }}>
            <TabPanel value="features">
              <div className="flex flex-wrap -mx-4">
                {features.map((feature, index) => (
                  <FeatureCard key={index} {...feature} />
                ))}
              </div>
            </TabPanel>
            <TabPanel value="details">
              <Card className="w-full shadow-lg">
                <CardBody>
                  <Typography variant="h4" color="blue-gray" className="mb-4">
                    Empowering Farmers with Technology
                  </Typography>
                  <Typography color="gray" className="mb-4">
                    Our AgriTech platform is designed to revolutionize farming practices. By leveraging cutting-edge technologies like AI, IoT, and data analytics, we provide farmers with the tools they need to optimize their operations, increase yields, and promote sustainable agriculture.
                  </Typography>
                  <Typography color="gray">
                    From real-time monitoring of crop health to predictive analytics for pest control, our comprehensive suite of features addresses every aspect of modern farming. We're committed to helping you stay ahead in an industry that's constantly evolving.
                  </Typography>
                </CardBody>
              </Card>
            </TabPanel>
          </TabsBody>
        </Tabs>

        <motion.div
          className="text-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
        >
          <Button
            size="lg"
            color="black"
            className="mt-8 rounded-full"
            ripple="light"
          >
            <a href="/#services">
            Explore All Features
            </a>
          </Button>
        </motion.div>
      </div>
    </section>
  );
};

export default Features;