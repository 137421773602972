import React from 'react';
import { Stepper as MTStepper, Step, Typography } from "@material-tailwind/react";

const Stepper = ({ activeStep, headings }) => {
  return (
    <div className="mb-8">
      {/* Desktop Stepper */}
      <div className="hidden md:block">
        <MTStepper activeStep={activeStep}>
          {headings.map((label, index) => (
            <Step key={label}>
              <div className="absolute -bottom-[2rem] w-max text-center">
                <Typography
                  variant="small"
                  color={activeStep === index ? "blue" : "blue-gray"}
                >
                  {label}
                </Typography>
              </div>
              {index + 1}
            </Step>
          ))}
        </MTStepper>
      </div>

      {/* Mobile Stepper */}
      <div className="md:hidden">
        {headings.map((label, index) => (
          <div key={label} className="flex items-center mb-4">
            <div className={`w-8 h-8 rounded-full flex items-center justify-center mr-4 ${index <= activeStep ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-600'
              }`}>
              {index + 1}
            </div>
            <Typography
              variant="small"
              color={index <= activeStep ? "blue" : "blue-gray"}
              className="flex-grow"
            >
              {label}
            </Typography>
            <div className="w-1/4 h-1 bg-gray-200 rounded">
              <div
                className="h-full bg-blue-500 rounded"
                style={{ width: `${index <= activeStep ? 100 : 0}%` }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stepper;