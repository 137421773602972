import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaExpand, FaCompress } from 'react-icons/fa';
import { useSpring, animated } from 'react-spring';
import { Carousel } from "@material-tailwind/react";
import livefeed1 from '../Assets/Surveillance/livefeed1.mp4';
import livefeed2 from '../Assets/Surveillance/livefeed2.mp4';
import livefeed3 from '../Assets/Surveillance/livefeed3.mp4';

const videos = [livefeed1, livefeed2, livefeed3];

const Surveillance = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [hoverState, setHoverState] = useState(false);

  const cardSpring = useSpring({
    scale: isExpanded ? 1.5 : 1,
    config: { mass: 1, tension: 280, friction: 60 }
  });

  const Corn = () => {
    const cornRef = React.useRef();
    useEffect(() => {
      if (cornRef.current) {
        cornRef.current.rotation.y += 0.01;
      }
    });
    return (
      <mesh ref={cornRef}>
        <cylinderGeometry args={[0.5, 0.3, 2, 20]} />
        <meshStandardMaterial color="#FFD700" />
      </mesh>
    );
  };

  return (
    <animated.div
      style={cardSpring}
      className={`relative w-full max-w-[48rem] overflow-hidden rounded-xl bg-gradient-to-br from-green-400 to-blue-500 p-1 shadow-2xl transition-all duration-300 ${isExpanded ? 'fixed inset-0 z-50 max-w-none' : ''}`}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
    >
      <motion.div
        className="relative aspect-w-16 aspect-h-9 rounded-lg overflow-hidden"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Carousel
          className="rounded-xl"
          navigation={({ setActiveIndex, activeIndex, length }) => (
            <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
              {new Array(length).fill("").map((_, i) => (
                <span
                  key={i}
                  className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                    }`}
                  onClick={() => setActiveIndex(i)}
                />
              ))}
            </div>
          )}
        >
          {videos.map((video, index) => (
            <video
              key={index}
              className="w-full h-full object-cover"
              src={video}
              title={`Surveillance Feed ${index + 1}`}
              autoPlay
              loop
            />
          ))}
        </Carousel>
      </motion.div>
      <div className="mt-4 p-4">
        <h2 className="text-2xl font-bold text-white mb-2">Field Surveillance</h2>
        <p className="text-green-100 mb-4">Real-time monitoring of Intruders</p>
        <div className="flex justify-between items-center">
          <div className="flex space-x-2">
            <span className="inline-block bg-green-200 rounded-full px-3 py-1 text-sm font-semibold text-green-700">Safe</span>
            <span className="inline-block bg-yellow-200 rounded-full px-3 py-1 text-sm font-semibold text-green-700">No Intruders</span>
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setIsExpanded(!isExpanded)}
            className="bg-white text-green-500 px-4 py-2 rounded-full shadow-md"
          >
            {isExpanded ? <FaCompress /> : <FaExpand />}
          </motion.button>
        </div>
      </div>
    </animated.div>
  );
};

export default Surveillance;
