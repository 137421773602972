import React, { useState, useEffect } from 'react';
import { Card, CardBody, Typography } from "@material-tailwind/react";
import { motion } from "framer-motion";
import ReactApexChart from "react-apexcharts";

export default function EnergyConsumption() {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        type: 'pie',
        background: '#000',
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        }
      },
      labels: ['Pumps', 'Valves', 'Sensors', 'Control Systems'],
      theme: {
        mode: 'dark',
        palette: 'palette4'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
    series: [44, 55, 13, 43]
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const newSeries = chartData.series.map(() => Math.floor(Math.random() * 100) + 10);
      setChartData(prevState => ({
        ...prevState,
        series: newSeries
      }));
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Card className="w-full overflow-hidden bg-gradient-to-br from-green-400 to-blue-500">
      <CardBody>
        <Typography variant="h4" color="white" className="mb-4 font-semibold">
          Energy Consumption
        </Typography>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="flex justify-center bg-black" // Center the chart
        >
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="pie"
            height={400} // Set height
            width={400} // Set width to make it a square
          />
        </motion.div>
        <Typography variant="h6" color="white" className="mt-4 text-center">
          Total Usage: <span className="font-bold text-green-300">{chartData.series.reduce((a, b) => a + b, 0)} kWh</span>
        </Typography>
      </CardBody>
    </Card>
  );
};