import { Typography } from "@material-tailwind/react";
import kiaaniot from '../Assets/LandingPage/saas-product.png'
const SaaSProduct = () => {
  return (
    <div className="text-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <Typography
          variant="h2"
          color="black"
          className="mb-8 text-center font-bold"
        >
          Our SaaS Product - Kiaaniot
        </Typography>
        <img src={kiaaniot} alt="kiaaniot" />
      </div>
    </div>
  )
}

export default SaaSProduct;